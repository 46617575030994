import React from "react";
import icon from "../img/icon.svg";
import { Link } from "react-router-dom";

const Logo = (props) => {
  return (
    <div {...props}>
      <Link to="/">
        <span className="sr-only">La Florida</span>
        <img className="h-15 w-15" src={icon} alt="" />
      </Link>
    </div>
  );
};

export default Logo;
