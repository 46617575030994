import React, { useContext, useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import dayjs from "dayjs";
import { StoreContext } from "../../Components/Store";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { shops } from "../Checkout/Components";

var isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(timezone);
dayjs.tz.setDefault("Russia/Ekaterinburg");

function Confirmed() {
  const [state, dispatch] = useContext(StoreContext);
  const [response, setResponse] = useState("");

  useEffect(() => {
    dispatch({ type: "RESET" });
    const [openHour, closeHour] =
      shops[0].workHours.weekRange[dayjs().isoWeekday() - 1];
    if (openHour <= dayjs().hour() && dayjs().hour() < closeHour)
      setResponse(true);
  }, []);

  return (
    <div className="mainWrapper">
      <div className="fixed w-full z-50">
        <Navbar />
      </div>
      <div className="bodywrapper pt-20">
        <h3 className="text-2xl font-semibold  mb-4">Заказ оформлен!</h3>
        <div className="mt-10 flex justify-center items-center">
          <div className="text-lg flex flex-col items-center rounded-lg bg-neutral px-4 py-6 shadow-md">
            <CheckCircleIcon className="w-10 m-2 text-gray-500" />
            {response
              ? "В течении 15 минут вам могут позвонить для уточнения детелей заказа."
              : "Спасибо за заказ. С вами свяжутся, как только заказ будет обработан."}
            <Link
              to="/"
              className="text-pink hover:text-accent italic underline"
            >
              Вернуться на главную
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Confirmed;
