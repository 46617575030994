import React, { useState, useEffect } from "react";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import mainImg from "../../img/ornella-binni-vSl1odYyd8w-unsplash.jpg";
import {
  YMaps,
  Map,
  ZoomControl,
  SearchControl,
  GeolocationControl,
  Polygon,
} from "react-yandex-maps";
import axios from "axios";
import { Link } from "react-router-dom";

const Payment = () => {
  return (
    <div className="mainWrapper">
      <div className="fixed w-full z-50">
        <Navbar />
      </div>
      <div className="bodywrapper text-lg">
        <div
          className="rounded-lg w-full mb-10 px-10 py-20"
          style={{
            backgroundImage: `linear-gradient(0.25turn,
          rgba(0, 0, 0, 0.5), 
          rgba(0, 0, 0, 0.2)
        ), url(${mainImg})`,
            backgroundPosition: "top",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <h2 className="text-2xl sm:text-3xl font-bold uppercase text-white max-w-xs">
            Условия доставки и оплаты
          </h2>
        </div>
        <div className="mb-10">
          <h3 className="text-xl uppercase font-semibold mb-3">
            Условия доставки
          </h3>
          <div>
            <p>Доставка осуществляется круглосуточно</p>

            <p>
              Заказы на доставку в день заказа принимаются в часы работы базы.
            </p>
            <p>Стоимость доставки зависит от отдаленности адреса.</p>
            <p>Актуальные цены доставки смотрите ниже.</p>
          </div>
        </div>
        <div className="mb-10">
          <h3 className="text-xl uppercase font-semibold mb-6">
            Зоны доставки
          </h3>
          <DeliveryZonesMap />
        </div>
        <div className="mb-10">
          <h3 className="text-xl uppercase font-semibold mb-3">Самовывоз</h3>
          <p>
            Вы можете забрать заказ в любом из наших{" "}
            <Link to="/contacts">магазинов</Link> в часы его работы. Услуга
            самовывоза бесплатная.
          </p>
        </div>
        <div className="mb-10">
          <h3 className="text-xl uppercase font-semibold mb-3">
            Способы оплаты
          </h3>
          <div>
            <ul>
              <li>Оплата наличными или картой при получении</li>
              <li>Оплата картой онлайн</li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Payment;

function DeliveryZonesMap() {
  const mapRef = React.createRef(null);
  const [Zones, setZones] = useState("");

  useEffect(() => {
    if (!Zones) {
      axios.get("geo_data.json").then((response) => {
        setZones(
          response.data.geometries.map((zone) => {
            return (
              <Polygon
                geometry={[zone.coordinates]}
                properties={{
                  balloonContent: `<div class="flex justify-center items-center text-lg font-semibold">${zone.price} руб</div>`,
                }}
                options={{
                  fillColor: zone.fillColor,
                  strokeColor: zone.strokeColor,
                  strokeWidth: 2,
                  fillOpacity: "0.5",
                  balloonCloseButton: false,
                }}
              />
            );
          })
        );
      });
    }
  }, []);

  return (
    Zones && (
      <YMaps query={{ apikey: "df909c87-04af-4943-9a7e-ddb73104a824" }}>
        <Map
          defaultState={{ center: [58.05, 56.23], zoom: 9 }}
          className="w-full min-h-[30rem] shadow-lg rounded-lg overflow-hidden"
          options={{
            restrictMapArea: [
              [58.23204846565946, 55.28727083902577],
              [57.80729166417003, 57.13297396402577],
            ],
          }}
          instanceRef={mapRef}
        >
          <ZoomControl />
          <SearchControl
            options={{
              placeholderContent: "Введите адрес доставки",
            }}
          />
          {Zones}
          <GeolocationControl />
        </Map>
      </YMaps>
    )
  );
}
