import React from "react";
import { PhoneIcon } from "@heroicons/react/outline";

const Search = () => {
  return (
    <div className="flex lg:ml-6 mt-1">
      <a
        href="tel:+73422286000"
        className="p-2 text-gray-400 hover:text-accent flex flex-row relative group"
      >
        <span className="sr-only">Phone</span>
        <PhoneIcon
          className="h-5 bg-white absolute md:pl-28 md:group-hover:-translate-x-32 md:group-hover:opacity-0 lg:group-hover:opacity-100 -z-10 right-0"
          aria-hidden="true"
        />
        <span className="whitespace-nowrap text-sm -z-20 w-0 md:w-fit overflow-hidden">
          +7 (342) 228 60 00
        </span>
      </a>
    </div>
  );
};

export default Search;
