import React, { useState } from "react";
import FlyoutMenu from "../../Components/FlyoutMenu";
import { BookOpenIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

export function CatalogBtn() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="shrink-0 grow">
      <button
        className="bg-white p-4 pr-5 rounded-xl w-full gap-2 items-center min-w-fit hidden md:flex 
                       sm:hover:scale-105 hover:shadow-xl shadow-lg group"
        onClick={() => {
          setIsOpen(true);
          document.body.scroll("no");
        }}
      >
        <BookOpenIcon className="w-6 text-pink" />
        Каталог
      </button>
      <Link
        className="bg-white p-4 pr-5 rounded-xl gap-2 items-center min-w-fit flex
            sm:hover:scale-105 hover:shadow-xl shadow-lg group md:hidden"
        to="/products/all"
      >
        <BookOpenIcon className="w-6 text-pink" />
        Каталог
      </Link>
      <FlyoutMenu isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
}
