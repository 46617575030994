const Reducer = (state, action) => {
  switch (action.type) {
    case "ADD_PRODUCT":
      const cartItems = state.cart;
      const itemPos = cartItems.findIndex(
        ({ _id }) => _id === action.payload.product._id
      );
      if (itemPos >= 0) {
        cartItems[itemPos].qty += 1;
      } else
        cartItems.push({
          ...action.payload.product,
          qty: 1,
        });

      window.localStorage.setItem(
        "cart",
        cartItems
          .map(({ _id, qty }) =>
            JSON.stringify({
              id: _id,
              qty: qty,
            })
          )
          .join(", ")
      );
      return {
        ...state,
        cart: cartItems,
        totalSum: cartItems
          .map(
            ({ price, qty, discount }) =>
              price * qty * (1 - (discount ? discount / 100 : 0))
          )
          .reduce(add, 0),
      };

    case "INC_PRODUCT":
      const cartItemsInc = state.cart;
      const idInc = action.payload;
      const itemPosInc = cartItemsInc.findIndex(({ _id }) => _id === idInc);

      if (itemPosInc >= 0) {
        cartItemsInc[itemPosInc].qty += 1;
      }

      window.localStorage.setItem(
        "cart",
        cartItemsInc
          .map(({ _id, qty }) =>
            JSON.stringify({
              id: _id,
              qty: qty,
            })
          )
          .join(", ")
      );
      return {
        ...state,
        cart: cartItemsInc,
        totalSum: cartItemsInc
          .map(
            ({ price, qty, discount }) =>
              price * qty * (1 - (discount ? discount / 100 : 0))
          )
          .reduce(add, 0),
      };
    case "DEC_PRODUCT":
      var cartItemsDec = state.cart;
      const idDec = action.payload;
      const itemPosDec = cartItemsDec.findIndex(({ _id }) => _id === idDec);
      if (itemPosDec >= 0) {
        cartItemsDec[itemPosDec].qty -= 1;
      }
      if (cartItemsDec[itemPosDec].qty === 0) {
        cartItemsDec = state.cart.filter(({ _id }) => _id !== idDec);
      }

      window.localStorage.setItem(
        "cart",
        cartItemsDec
          .map(({ _id, qty }) =>
            JSON.stringify({
              id: _id,
              qty: qty,
            })
          )
          .join(", ")
      );
      return {
        ...state,
        cart: cartItemsDec,
        totalSum: cartItemsDec
          .map(
            ({ price, qty, discount }) =>
              price * qty * (1 - (discount ? discount / 100 : 0))
          )
          .reduce(add, 0),
      };
    case "REMOVE_PRODUCT":
      var cartItemsRemove = state.cart;
      const idRemove = action.payload;
      const itemPosRemove = cartItemsRemove.findIndex(
        ({ _id }) => _id === idRemove
      );
      if (itemPosRemove >= 0) {
        cartItemsRemove = state.cart.filter(({ _id }) => _id !== idRemove);
      }
      window.localStorage.setItem(
        "cart",
        cartItemsRemove
          .map(({ _id, qty }) =>
            JSON.stringify({
              id: _id,
              qty: qty,
            })
          )
          .join(", ")
      );
      return {
        ...state,
        cart: cartItemsRemove,
        totalSum: cartItemsRemove
          .map(
            ({ price, qty, discount }) =>
              price * qty * (1 - (discount ? discount / 100 : 0))
          )
          .reduce(add, 0),
      };
    case "RESET":
      return { ...state, cart: [], totalSum: 0 };
    default:
      return state;
  }
};

export default Reducer;

function add(accumulator, a) {
  return accumulator + a;
}
