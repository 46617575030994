import React, { useContext } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { ChevronRightIcon, XIcon } from "@heroicons/react/outline";
import { StoreContext } from "./Store";

function FlyoutMenu({ isOpen, setIsOpen }) {
  const [state, dispatch] = useContext(StoreContext);
  const { categories, pages } = state;
  return (
    <Transition
      show={isOpen}
      enter="transition duration-200 ease-out"
      enterFrom="opacity-0"
      enterTo="opacity-100 "
      leave="transition duration-200 ease-out"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="z-40 hidden md:block bg-white h-screen w-screen fixed top-0 left-0"
      >
        <Dialog.Overlay />
        <div className="max-w-7xl mx-auto bodywrapper">
          <div className="flex flex-row justify-between items-center mb-6">
            <Dialog.Title className="text-2xl font-semibold ">
              Каталог
            </Dialog.Title>

            <button
              onClick={(e) => {
                setIsOpen(false);
                document.body.scroll("yes");
              }}
            >
              <XIcon className="h-6 w-6 text-gray-400" />
            </button>
          </div>

          <div className="relative w-full h-full grid md:grid-cols-2 lg:grid-cols-3 gap-10 justify-items-center">
            {categories.map((section) => (
              <div key={section.name}>
                <div className="relative">
                  <img
                    src={section.img}
                    alt=""
                    className="w-14 h-14 absolute -left-16 top-0 object-cover"
                  />
                  <a
                    id={`${section.name}-heading`}
                    className="font-semibold text-xl  hover:text-accent"
                    href={`/products/0=${section.name}`}
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    {section.name}
                  </a>
                </div>

                <ul
                  aria-labelledby={`${section.name}-heading`}
                  className="mt-4 pl-4 space-y-4 sm:mt-3 sm:space-y-3"
                >
                  {section.items.map((item) => (
                    <li key={item}>
                      <a
                        href={`/products/1=${item}`}
                        className="hover:text-pink text-lg "
                        onClick={() => {
                          setIsOpen(false);
                        }}
                      >
                        {item}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <a
            href="/products/all"
            className="text-gray-500 text-lg sm:text-xl hover:underline hover:text-pink leading-5 text-right whitespace-nowrap"
          >
            Смотреть все
            <ChevronRightIcon className="h-4 inline-block" />
          </a>
        </div>
      </Dialog>
    </Transition>
  );
}

export default FlyoutMenu;
