import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { PhoneIcon, TruckIcon } from "@heroicons/react/outline";
import { YMaps, Map, Placemark, ZoomControl } from "react-yandex-maps";
import { AddressSuggestions } from "react-dadata";
import "react-dadata/dist/react-dadata.css";
import icon from "../../img/icon.svg";
import { getIn } from "formik";
import axios from "axios";
var pointInPolygon = require("point-in-polygon");

export const shops = [
  {
    id: "1",
    address: "Самолётная, 74",
    workHours: {
      weekRangeStr: ["Пн-Пт", "Сб", "Вс"],
      hoursStr: ["9:00-18:00", "10:00-17:00", "Выходной"],
      weekRange: [
        [9, 18],
        [9, 18],
        [9, 18],
        [9, 18],
        [9, 18],
        [10, 17],
        [0, 0],
      ],
    }, //"будни 9:00-18:00, сб 10:00-17:00, вс выходной",
    phoneNumber: "+79194606111",
    coords: [57.968964, 56.206473],
  },
  {
    id: "2",
    address: "Героев Хасана, 3",
    workHours: {
      weekRangeStr: ["Пн-Сб", "Вс"],
      hoursStr: ["9:00-20:00", "10:00-20:00"],
      weekRange: [
        [9, 20],
        [9, 20],
        [9, 20],
        [9, 20],
        [9, 20],
        [9, 20],
        [10, 20],
      ],
    }, //"пн-сб 8:00-21:00, вс 9:00-20:00",
    phoneNumber: "+79991266330",

    coords: [57.994402, 56.255396],
  },
  // {
  //   id: "3",
  //   address: "ЦУМ, Ленина, 45",
  //   workHours: {
  //     weekRangeStr: ["Пн-Сб", "Вс"],
  //     hoursStr: ["10:00-21:00", "10:00-20:00"],
  //     weekRange: [
  //       [10, 21],
  //       [10, 21],
  //       [10, 21],
  //       [10, 21],
  //       [10, 21],
  //       [10, 21],
  //       [10, 20],
  //     ],
  //   }, //"пн-сб 10:00-21:00, вс 10:00-20:00",
  //   phoneNumber: "+79991232551",

  //   coords: [58.011776, 56.237753],
  // },
];

var isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(timezone);
dayjs.tz.setDefault("Russia/Ekaterinburg");
require("dayjs/locale/ru");
dayjs().locale("ru").format();

// const AdressMap = ({ state, setOpenMap, address }) => {
//   return (
//     state && (
//       <div className="inset-0 fixed bg-black/20 z-50 flex justify-center items-center">
//         <span
//           onClick={(e) => {
//             e.preventDefault();
//             setOpenMap(false);
//           }}
//           className="inset-0 absolute hover:cursor-pointer -z-10"
//         ></span>
//         <div className="w-[min(max(400px,_60%),_95%)] h-2/3 p-6 bg-neutral rounded-md relative">
//           <XIcon
//             className="absolute h-5 w-5 text-gray-500 top-1 right-1 hover:cursor-pointer hover:text-gray-400"
//             onClick={(e) => {
//               e.preventDefault();
//               setOpenMap(false);
//             }}
//           />
//           <YMaps>
//             <Map
//               defaultState={state}
//               width="100%"
//               height="100%"
//               options={{ minZoom: 10 }}
//             >
//               <Placemark
//                 geometry={state.center}
//                 options={{
//                   preset: "islands#deliveryCircleIcon",
//                   iconColor: "#49ae49",
//                 }}
//               ></Placemark>
//               <ZoomControl />
//               <GeolocationControl
//                 options={{
//                   float: "left",
//                 }}
//               />
//             </Map>
//           </YMaps>
//         </div>
//       </div>
//     )
//   );
// };

const fieldsetClass =
  "shadow-md border-t-4 border-t-accent px-4 py-3 pb-4 rounded-md gap-4 w-full flex flex-wrap justify-start";

export const CustomerFieldset = ({ formik, refId }) => {
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(
      (formik.touched.fullName && Boolean(formik.errors.fullName)) ||
        (formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)) ||
        (formik.touched.email && Boolean(formik.errors.email))
    );
  }, [formik.values, formik.errors]);

  return (
    <fieldset
      className={(error ? "relative border-t-pink" : "") + " " + fieldsetClass}
      ref={refId}
    >
      <legend className="text-xl">Данные заказчика</legend>
      <TextField
        id="name-textfield"
        name="fullName"
        label="Имя *"
        variant="outlined"
        size="small"
        className="grow"
        value={formik.values.fullName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.fullName && Boolean(formik.errors.fullName)}
        helperText={formik.touched.fullName && formik.errors.fullName}
      />
      <TextField
        id="phone-textfield"
        name="phoneNumber"
        label="Телефон *"
        variant="outlined"
        size="small"
        className="grow"
        value={formik.values.phoneNumber}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
      />
      <TextField
        id="email-textfield"
        name="email"
        label="Почта"
        variant="outlined"
        size="small"
        className="grow"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
    </fieldset>
  );
};

export const AddressFieldset = ({ formik, refId, setDelivery }) => {
  const [error, setError] = useState(false);
  const [minTime, setMinTime] = useState(null);
  const [zones, setZones] = useState([]);
  const [hours, setHours] = useState([]);
  const [time, setTime] = useState({
    hours: dayjs().hour() + 2,
    minutes: ~~(dayjs().minute() / 30) * 3,
  });
  const [specialTimePicker, setSTP] = useState(
    formik.values.shippingInfo.date &&
      formik.values.shippingInfo.date.month() === 2 &&
      (formik.values.shippingInfo.date.date() === 7 ||
        formik.values.shippingInfo.date.date() === 8)
  );

  useEffect(() => {
    if (!zones.length) {
      axios.get("geo_data.json").then((response) => {
        setZones(response.data.geometries);
      });
    }
  }, []);

  useEffect(() => {
    const [openHour, closeHour] =
      shops[0].workHours.weekRange[
        formik.values.shippingInfo.date.isoWeekday() - 1
      ];

    const dayDiff = formik.values.shippingInfo.date
      .hour(23)
      .minute(59)
      .diff(dayjs(), "hour");

    if (
      formik.values.shippingInfo.date.month() === 2 &&
      (formik.values.shippingInfo.date.date() === 7 ||
        formik.values.shippingInfo.date.date() === 8)
    ) {
      setSTP(true);
      formik.handleChange({
        target: {
          name: "shippingInfo.time",
          value: "09:00 - 13:00",
        },
      });
    } else {
      setSTP(false);
      setMinTime(
        dayDiff >= openHour
          ? dayDiff < closeHour
            ? dayjs().add(2, "hours")
            : dayDiff < 48
            ? formik.values.shippingInfo.date.add(openHour + 2, "hours")
            : null
          : formik.values.shippingInfo.date.add(openHour + 2, "hours")
      );
    }
  }, [formik.values.shippingInfo.date]);

  useEffect(() => {
    const start = minTime ? minTime.hour() : 0;
    setHours(Array.from({ length: 24 - start }, (_, i) => start + i));
    formik.handleChange({
      target: {
        name: "shippingInfo.time",
        value: time.hours + ":" + time.minutes + "0",
      },
    });
  }, [minTime]);

  useEffect(() => {
    setError(
      (getIn(formik.touched, "shippingInfo.address.data.house") &&
        Boolean(getIn(formik.errors, "shippingInfo.address.data.house"))) ||
        (formik.touched.date && Boolean(formik.errors.date))
    );
    if (formik.values.shippingInfo.address.data.house) {
      var delivery = "По договоренности";
      zones.forEach(({ price, coordinates }) => {
        if (
          pointInPolygon(
            [
              formik.values.shippingInfo.address.data.geo_lat,
              formik.values.shippingInfo.address.data.geo_lon,
            ],
            coordinates
          )
        )
          delivery = price;
      });
      setDelivery(delivery);
    } else setDelivery(0);
  }, [formik.values.shippingInfo, formik.errors]);

  return (
    <fieldset
      className={
        ((getIn(formik.touched, "shippingInfo.address.data.house") &&
          Boolean(getIn(formik.errors, "shippingInfo.address.data.house"))) ||
        (formik.touched.date && Boolean(formik.errors.date))
          ? "relative border-t-pink"
          : "") +
        " " +
        fieldsetClass
      }
      ref={refId}
    >
      <legend className="text-xl">Адрес доставки</legend>
      <AddressSuggestions
        token={process.env.REACT_APP_DADATA_TOKEN}
        value={formik.values.shippingInfo.address}
        onChange={(e) => {
          formik.handleChange({
            target: { name: "shippingInfo.address", value: e },
          });
        }}
        count={5}
        suggestionClassName="hover:bg-neutral p-2 flex flex-col w-full items-stretch text-left"
        highlightClassName="text-pink"
        selectOnBlur={true}
        currentSuggestionClassName="bg-accent/40 shadow-sm"
        containerClassName="grow relative"
        filterLocations={[{ region: "Пермский" }]}
        filterFromBound="street"
        filterToBound="house"
        customInput={(e) => (
          <FormControl variant="outlined" size="small" className="w-full">
            <InputLabel
              htmlFor="outlined-adornment-password"
              className={
                "bg-white px-1 -ml-1 " +
                (getIn(formik.touched, "shippingInfo.address.data.house") &&
                Boolean(getIn(formik.errors, "shippingInfo.address.data.house"))
                  ? "text-pink"
                  : "")
              }
            >
              Адрес *
            </InputLabel>
            <OutlinedInput
              id="address-textfield"
              name="shippingInfo.address"
              label="Адрес *"
              inputProps={{ ...e, className: "" }}
              onChange={(e) => {
                e.preventDefault();
                if (!e.target.value) {
                  formik.handleChange({
                    target: {
                      name: "shippingInfo.address",
                      value: {
                        data: { house: "", geo_lat: "", geo_lon: "" },
                      },
                    },
                  });
                  setDelivery(0);
                }
              }}
              className="w-full"
              error={
                getIn(formik.touched, "shippingInfo.address.data.house") &&
                Boolean(getIn(formik.errors, "shippingInfo.address.data.house"))
              }
              helperText={
                getIn(formik.touched, "shippingInfo.address.data.house") &&
                getIn(formik.errors, "shippingInfo.address.data.house")
              }
            />
            {getIn(formik.touched, "shippingInfo.address.data.house") &&
              Boolean(
                getIn(formik.errors, "shippingInfo.address.data.house")
              ) && (
                <span className="text-sm text-pink pl-2 leading-3 mt-1">
                  * Введите полный адрес
                </span>
              )}
          </FormControl>
        )}
      />
      {formik.values.shippingInfo.address.data.house && (
        <span className="grow flex gap-4 flex-wrap">
          <TextField
            id="flat-textfield"
            label="Кв."
            variant="outlined"
            size="small"
            className="min-w-[4rem] max-w-[5rem] shrink"
            name="shippingInfo.flat"
            onChange={formik.handleChange}
          />
          <TextField
            id="entrance-textfield"
            label="Подъезд"
            variant="outlined"
            size="small"
            className="min-w-[5rem] max-w-[6rem]"
            name="shippingInfo.entrance"
            onChange={formik.handleChange}
          />
          <TextField
            id="floor-textfield"
            label="Этаж"
            variant="outlined"
            size="small"
            className="min-w-[4.5rem] max-w-[5rem]"
            name="shippingInfo.floor"
            onChange={formik.handleChange}
          />
          <TextField
            id="flat-textfield"
            label="Домофон"
            variant="outlined"
            size="small"
            className="min-w-[6rem] max-w-[7rem]"
            name="shippingInfo.intercom"
            onChange={formik.handleChange}
          />
        </span>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <div className="flex flex-row grow gap-4">
          <DatePicker
            label="Дата"
            value={formik.values.shippingInfo.date}
            name="shippingInfo.date"
            onChange={(e) =>
              formik.handleChange({
                target: {
                  name: "shippingInfo.date",
                  value: e.hour(0).minute(0).second(0),
                },
              })
            }
            renderInput={(params) => <TextField {...params} />}
            minDate={dayjs()
              .add(
                25 - shops[0].workHours.weekRange[dayjs().isoWeekday() - 1][1],
                "hours"
              )
              .hour(0)
              .minute(0)
              .second(0)}
            maxDate={dayjs().add(1, "year")}
            className={
              "w-[9rem] grow" + getIn(formik.touched, "shippingInfo.date") &&
              Boolean(getIn(formik.errors, "shippingInfo.date"))
                ? " text-pink"
                : ""
            }
            error={
              getIn(formik.touched, "shippingInfo.date") &&
              Boolean(getIn(formik.errors, "shippingInfo.date"))
            }
            helperText={
              getIn(formik.touched, "shippingInfo.date") &&
              getIn(formik.errors, "shippingInfo.date")
            }
          />
          {/* <TimePicker
            label="Время"
            value={formik.values.shippingInfo.time}
            name="shippingInfo.time"
            onChange={(e) =>
              formik.handleChange({
                target: { name: "shippingInfo.time", value: e },
              })
            }
            renderInput={(params) => <TextField {...params} />}
            minTime={minTime}
            error={
              getIn(formik.touched, "shippingInfo.time") &&
              Boolean(getIn(formik.errors, "shippingInfo.time"))
            }
            helperText={
              getIn(formik.touched, "shippingInfo.time") &&
              getIn(formik.errors, "shippingInfo.time")
            }
          /> */}
          {specialTimePicker ? (
            <FormControl className=" w-44">
              <InputLabel>Часы</InputLabel>
              <Select
                label="Часы"
                onChange={(e) => {
                  formik.handleChange({
                    target: {
                      name: "shippingInfo.time",
                      value: e.target.value,
                    },
                  });
                }}
                value={formik.values.shippingInfo.time}
              >
                <MenuItem value={"09:00 - 13:00"}>09:00 - 13:00</MenuItem>
                <MenuItem value={"13:00 - 17:00"}>13:00 - 17:00</MenuItem>
                <MenuItem value={"17:00 - 21:00"}>17:00 - 21:00</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <>
              <FormControl className="w-24">
                <InputLabel>Часы</InputLabel>
                <Select
                  label="Часы"
                  onChange={(e) => {
                    setTime({ ...time, hours: e.target.value });
                    formik.handleChange({
                      target: {
                        name: "shippingInfo.time",
                        value: e.target.value + ":" + time.minutes + "0",
                      },
                    });
                  }}
                  value={time.hours}
                >
                  {hours.map((value) => (
                    <MenuItem value={value}>{value}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className="w-32">
                <InputLabel>Минуты</InputLabel>
                <Select
                  label="Минуты"
                  onChange={(e) => {
                    setTime({ ...time, minutes: e.target.value });
                    formik.handleChange({
                      target: {
                        name: "shippingInfo.time",
                        value: time.hours + ":" + e.target.value + "0",
                      },
                    });
                  }}
                  value={time.minutes}
                >
                  <MenuItem value={0}>00</MenuItem>
                  <MenuItem value={3}>30</MenuItem>
                </Select>
              </FormControl>
            </>
          )}
        </div>
        {((getIn(formik.touched, "shippingInfo.date") &&
          Boolean(getIn(formik.errors, "shippingInfo.date"))) ||
          (getIn(formik.touched, "shippingInfo.time") &&
            Boolean(getIn(formik.errors, "shippingInfo.time")))) && (
          <span className="text-sm text-pink pl-2 leading-3 -my-2">
            * Обязательно к заполнению
          </span>
        )}
      </LocalizationProvider>
      <FormControlLabel
        control={
          <Switch
            name="shippingInfo.isShipToAnother"
            checked={formik.values.isShipToAnother}
            onChange={formik.handleChange}
          />
        }
        label="Другому человеку"
        className="grow"
      />
      <TextField
        id="name-textfield"
        label="Коментарий"
        name="shippingInfo.comment"
        value={formik.values.comment}
        onChange={formik.handleChange}
        variant="outlined"
        size="small"
        multiline
        rows={4}
        className="grow"
      />
    </fieldset>
  );
};

export const RecipientFieldset = ({ formik, refId }) => {
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(
      (getIn(formik.touched, "shippingInfo.recipInfo.name") &&
        Boolean(getIn(formik.errors, "shippingInfo.recipInfo.name"))) ||
        (getIn(formik.touched, "shippingInfo.recipInfo.phone") &&
          Boolean(getIn(formik.errors, "shippingInfo.recipInfo.phone")))
    );
  }, [formik.values, formik.errors]);

  return (
    <fieldset
      className={(error ? "relative border-t-pink" : "") + " " + fieldsetClass}
      ref={refId}
    >
      <legend className="text-xl">Данные получателя</legend>
      <TextField
        id="name-textfield"
        label="Имя получателя *"
        name="shippingInfo.recipInfo.name"
        variant="outlined"
        size="small"
        className="grow"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={
          getIn(formik.touched, "shippingInfo.recipInfo.name") &&
          Boolean(getIn(formik.errors, "shippingInfo.recipInfo.name"))
        }
        helperText={
          getIn(formik.touched, "shippingInfo.recipInfo.name") &&
          getIn(formik.errors, "shippingInfo.recipInfo.name")
        }
      />
      <TextField
        id="phone-textfield"
        label="Телефон получателя *"
        name="shippingInfo.recipInfo.phone"
        variant="outlined"
        size="small"
        className="grow"
        value={formik.values.phone}
        onChange={formik.handleChange}
        error={
          getIn(formik.touched, "shippingInfo.recipInfo.phone") &&
          Boolean(getIn(formik.errors, "shippingInfo.recipInfo.phone"))
        }
        helperText={
          getIn(formik.touched, "shippingInfo.recipInfo.phone") &&
          getIn(formik.errors, "shippingInfo.recipInfo.phone")
        }
      />
      <TextField
        id="name-textfield"
        label="Текст открытки"
        name="shippingInfo.recipInfo.note"
        variant="outlined"
        size="small"
        multiline
        rows={4}
        helperText="Мы подарим открытку от вашего имени!"
        className="grow"
        value={formik.values.note}
        onChange={formik.handleChange}
      />
    </fieldset>
  );
};

export const PickupAddressFieldset = ({ formik, refId }) => {
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(
      (getIn(formik.touched, "pickUpInfo.shop") &&
        Boolean(getIn(formik.errors, "pickUpInfo.shop"))) ||
        (formik.touched.date && Boolean(formik.errors.date))
    );
  }, [formik.values.pickUpInfo, formik.errors]);

  function chooseShop(e) {
    if (e.target.checked) {
      Array.from(document.querySelectorAll(".placemark")).forEach(function (
        el
      ) {
        el.classList.remove("border-accent");
        el.classList.remove("scale-125");
        el.classList.add("border-pink");
      });
      var element = document.getElementById("placemark-" + e.target.id);
      element.classList.add("border-accent");
      element.classList.add("scale-125");
      element.classList.remove("border-pink");
      formik.handleChange(e);
    }
  }
  return (
    <fieldset
      className={(error ? "border-t-pink" : "") + " " + fieldsetClass}
      ref={refId}
    >
      <legend className="text-xl">Пункт самовывоза *</legend>
      {getIn(formik.touched, "pickUpInfo.shop") &&
        Boolean(getIn(formik.errors, "pickUpInfo.shop")) && (
          <span className="text-sm text-pink pl-2 leading-3 -my-2">
            {formik.errors.pickUpInfo.shop}
          </span>
        )}
      <div className="flex gap-2 w-full flex-wrap items-stretch">
        {shops.map((shop) => (
          <div className="min-w-[10rem] grow shrink-0">
            <input
              type="radio"
              id={shop.id}
              name="pickUpInfo.shop"
              value={shop.id}
              checked={shop.id === formik.values.pickUpInfo.shop ? true : false}
              onChange={chooseShop}
              className="hidden peer"
            />
            <label
              for={shop.id}
              className="flex flex-col gap-2 border-2 shadow-md border-white peer-checked:border-accent group peer-checked:shadow-accent/50 rounded-md p-2 hover:cursor-pointer hover:border-accent h-full"
            >
              <h4 className="font-semibold whitespace-nowrap flex gap-1">
                <TruckIcon className="w-4 inline-block text-gray-500" />
                {shop.address}
              </h4>
              <table className="text-sm text-gray-600 w-fit">
                {shop.workHours.weekRangeStr.map((el, index) => (
                  <tr>
                    <td className="text-pink pr-1 font-semibold text-right">
                      {el}:
                    </td>
                    <td>{shop.workHours.hoursStr[index]}</td>
                  </tr>
                ))}
              </table>
              <a
                href={"tel:" + shop.phoneNumber}
                className="mt-auto text-sm flex gap-1 hover:opacity-60 w-min"
              >
                <PhoneIcon className="w-4 text-accent inline-block" />
                {shop.phoneNumber}
              </a>
            </label>
          </div>
        ))}
      </div>
      <div className="p-2 w-full aspect-[5/4] rounded-md relative overflow-hidden">
        {/* <div className="relative w-full h-full"> */}
        <CustomTemplate chooseShop={chooseShop} />
        {/* </div> */}
      </div>
    </fieldset>
  );
};

export const PickupTimeFieldset = ({ formik, shopId, refId }) => {
  const [hourRange, setHR] = useState([]);
  const [shop, setShop] = useState("");
  const [time, setTime] = useState({
    hours: dayjs().hour() + 2,
    minutes: ~~(dayjs().minute() / 30) * 3,
  });

  const range = (start, end) => {
    const length = end - start;
    return Array.from({ length }, (_, i) => start + i);
  };

  const [error, setError] = useState(false);

  useEffect(() => {
    setError(
      (formik.touched.date && Boolean(formik.errors.date)) ||
        (formik.touched.time && Boolean(formik.errors.time))
    );
  }, [formik.values, formik.errors]);

  useEffect(() => {
    if (shop) {
      const dayOfWeek =
        shop.workHours.weekRange[
          (dayjs(formik.values.pickUpInfo.date).day() + 6) % 7
        ];
      if (dayOfWeek[0] + dayOfWeek[1]) {
        setHR(
          range(
            formik.values.pickUpInfo.date.diff(
              dayjs().hour(0).minute(0),
              "hours"
            ) >
              24 -
                shop.workHours.weekRange[
                  formik.values.pickUpInfo.date.isoWeekday() - 1
                ][1]
              ? dayOfWeek[0]
              : dayjs().add(1.5, "hours").hour(),
            dayOfWeek[1]
          )
        );
      } else {
        setHR([]);
        formik.handleChange({
          target: {
            name: "pickUpInfo.time",
            value: "",
          },
        });
      }
    }
  }, [shop, formik.values.pickUpInfo.date]);

  useEffect(() => {
    if (shopId && shopId !== shop.id) {
      setShop(shops.find(({ id }) => shopId === id));
    }
  }, [shopId]);

  useEffect(() => {
    formik.handleChange({
      target: {
        name: "pickUpInfo.time",
        value: time.hours + ":" + time.minutes + "0",
      },
    });
  }, [time]);

  return (
    shop && (
      <fieldset
        className={
          (error ? "relative border-t-pink" : "") + " " + fieldsetClass
        }
        ref={refId}
      >
        <legend className="text-xl">Время самовывоза</legend>
        <div className="flex flex-row grow gap-4">
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
            <DatePicker
              label="Дата"
              value={formik.values.pickUpInfo.date}
              name="pickUpInfo.date"
              onChange={(e) =>
                formik.handleChange({
                  target: {
                    name: "pickUpInfo.date",
                    value: e.hour(0).minute(0),
                  },
                })
              }
              renderInput={(params) => <TextField {...params} />}
              minDate={dayjs()
                .add(1, "day")
                .subtract(
                  shop.workHours.weekRange[dayjs().isoWeekday() - 1][1],
                  "hours"
                )}
              maxDate={dayjs().add(1, "year")}
              className={
                "w-[10rem] grow" + formik.touched.date &&
                Boolean(formik.errors.date)
                  ? " text-pink"
                  : ""
              }
              error={
                getIn(formik.touched, "pickUpInfo.date") &&
                Boolean(getIn(formik.errors, "pickUpInfo.date"))
              }
              helperText={
                getIn(formik.touched, "pickUpInfo.date") &&
                getIn(formik.errors, "pickUpInfo.date")
              }
            />
            {/* <TimePicker
              label="Время"
              value={formik.values.pickUpInfo.time}
              name="pickUpInfo.time"
              onChange={(e) =>
                formik.handleChange({
                  target: { name: "pickUpInfo.time", value: e },
                })
              }
              renderInput={(params) => <TextField {...params} />}
              minTime={
                formik.values.pickUpInfo.date.diff(
                  dayjs().hour(0).minute(0),
                  "hours"
                ) >
                24 -
                  shop.workHours.weekRange[
                    formik.values.pickUpInfo.date.isoWeekday() - 1
                  ][1]
                  ? formik.values.pickUpInfo.date.hour(
                      shop.workHours.weekRange[
                        formik.values.pickUpInfo.date.isoWeekday() - 1
                      ][0]
                    )
                  : dayjs().add(1.5, "hours")
              }
              maxTime={formik.values.pickUpInfo.date
                .hour(shop.workHours.weekRange[dayjs().isoWeekday() - 1][1] - 1)
                .minute(30)}
              error={
                getIn(formik.touched, "pickUpInfo.time") &&
                Boolean(getIn(formik.errors, "pickUpInfo.time"))
              }
              helperText={
                getIn(formik.touched, "pickUpInfo.time") &&
                getIn(formik.errors, "pickUpInfo.time")
              }
            /> */}
            <FormControl className="w-24">
              <InputLabel>Часы</InputLabel>
              <Select
                label="Часы"
                onChange={(e) => {
                  setTime({ ...time, hours: e.target.value });
                }}
                value={time.hours}
                error={
                  getIn(formik.touched, "pickUpInfo.time") &&
                  Boolean(getIn(formik.errors, "pickUpInfo.time"))
                }
                helperText={
                  getIn(formik.touched, "pickUpInfo.time") &&
                  getIn(formik.errors, "pickUpInfo.time")
                }
              >
                {hourRange.map((value) => (
                  <MenuItem value={value}>{value}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="w-32">
              <InputLabel>Минуты</InputLabel>
              <Select
                label="Минуты"
                onChange={(e) => {
                  setTime({ ...time, minutes: e.target.value });
                }}
                value={time.minutes}
                error={
                  getIn(formik.touched, "pickUpInfo.time") &&
                  Boolean(getIn(formik.errors, "pickUpInfo.time"))
                }
                helperText={
                  getIn(formik.touched, "pickUpInfo.time") &&
                  getIn(formik.errors, "pickUpInfo.time")
                }
              >
                <MenuItem value={0}>00</MenuItem>
                <MenuItem value={3}>30</MenuItem>
              </Select>
            </FormControl>
          </LocalizationProvider>
        </div>
        {((formik.touched.date && Boolean(formik.errors.date)) ||
          (formik.touched.time && Boolean(formik.errors.time))) && (
          <span className="text-sm text-pink pl-2 leading-3 -my-2">
            * Обязательно к заполнению
          </span>
        )}
        <TextField
          id="name-textfield"
          label="Коментарий"
          name="comment"
          value={formik.values.comment}
          variant="outlined"
          size="small"
          multiline
          rows={4}
          className="grow"
          onChange={formik.handleChange}
        />
      </fieldset>
    )
  );
};

export class CustomTemplate extends React.Component {
  constructor({ chooseShop }) {
    super({ chooseShop });

    this.state = { template: null, ymaps: null };

    this.onLoad = (ymaps) => {
      this.setState({ ymaps: ymaps });
    };

    this.createTemplate = (id) => {
      var Chips = this.state.ymaps.templateLayoutFactory.createClass(
        `<div class="w-9 h-9 relative block -mt-4 -ml-4"><img class="placemark border-pink w-full h-full object-contain bg-neutral border-4 rounded-full p-1" id=${
          "placemark-" + id
        } src=${icon}></img><span class="absolute text-xs whitespace-nowrap top-1/2 -translate-y-1/2 right-2 translate-x-full bg-white border border-gray-700 font-semibold py-1 pl-[0.85rem] pr-2 rounded-r-full -z-10">$[properties.iconCaption]</span></div>`,
        {
          build: function () {
            Chips.superclass.build.call(this);
            var radioBtn = document.getElementById(id);
            if (radioBtn.checked) {
              var element = document.getElementById("placemark-" + id);
              element.classList.add("border-accent");
              element.classList.add("scale-125");
              element.classList.remove("border-pink");
            }
            if (!this.inited) {
              this.inited = true;
              this.getData().geoObject.events.add(
                "click",
                function () {
                  var radioBtn = document.getElementById(id);
                  if (!radioBtn.checked) {
                    radioBtn.click();
                    this.rebuild();
                  }
                },
                this
              );
            }
          },
        }
      );

      const circleShape = {
        type: "Circle",
        coordinates: [0, 0],
        radius: 20,
      };
      return {
        iconLayout: Chips,
        iconShape: circleShape,
        hideIconOnBalloonOpen: false,
      };
    };
  }

  render() {
    return (
      <YMaps>
        <Map
          // Event handler was moved from onApiAvaliable on YMaps to onLoad on Map
          onLoad={this.onLoad}
          defaultState={{ center: [57.993, 56.237], zoom: 11 }}
          className="absolute inset-0"
          options={{
            minZoom: 11,
            maxZoom: 17,
            restrictMapArea: [
              [57.9, 56],
              [58.1, 56.5],
            ],
          }}
          // We are also loading `templateLayoutFactory` additionally to Map
          modules={["templateLayoutFactory"]}
        >
          {this.state.ymaps && (
            <>
              {shops.map((shop) => (
                <Placemark
                  geometry={shop.coords}
                  properties={{ iconCaption: shop.address }}
                  options={this.createTemplate(shop.id)}
                  modules={["geoObject.addon.balloon"]}
                />
              ))}
              <ZoomControl />
            </>
          )}
        </Map>
      </YMaps>
    );
  }
}
