import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { StoreContext } from "../../Components/Store";
import defaultImg from "../../img/lauren-mancke-DpphPG9ENsI-unsplash.jpg";

export default function CategoryList() {
  const [{ categories }, dispatch] = useContext(StoreContext);

  return (
    categories.length && (
      <div className="max-w-7xl mx-auto px-8 sm:px-0">
        <div className="py-6 sm:py-8 lg:py-10 lg:max-w-none">
          <h2 className="text-xl sm:text-2xl">Категории</h2>
          <div className="mt-6 flex flex-wrap gap-4 justify-center">
            {categories.map((callout) => (
              <Link
                to={"products/0=" + callout.name}
                key={callout.name}
                className="bg-white group relative max-h-64 max-w-sm sm:max-w-full sm:aspect-1 rounded-lg overflow-hidden grow"
              >
                <img
                  src={callout.img ? callout.img : defaultImg}
                  alt={callout.name}
                  className="absolute w-full h-full object-center object-cover group-hover:opacity-50"
                />
                <div className="px-4 w-1/2 z-10 relative">
                  <h3 className="mt-6 mb-2 text-xl sm:text-2xl text-gray-900">
                    <p>
                      <span className="absolute inset-0" />
                      {callout.name}
                    </p>
                  </h3>
                  <p className="text-base  mb-6">{callout.description}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    )
  );
}
