import { CheckIcon, ShoppingBagIcon } from "@heroicons/react/outline";
import React, { useContext, useState } from "react";
import BreadcrumbNav from "../../Components/BreadcrumbNav";
import { StoreContext } from "../../Components/Store";
import defaultImg from "../../img/defaultImg.png";

export default function ProductInfo({ product }) {
  const [state, dispatch] = useContext(StoreContext);
  const bc1 = state.categories.find(({ items }) =>
    items.includes(product.category)
  ).name;
  const bc2 = product.category;
  const breadcrumbs = [
    {
      name: "Все товары",
      href: "/products/all",
      key: "all",
    },
    {
      name: bc1,
      key: bc1,
      href: `/products/0=${bc1}`,
    },
    {
      name: bc2,
      key: bc2,
      href: `/products/1=${bc2}`,
    },
  ];
  const [isAdded, setAdded] = useState(false);
  const addToCart = (e) => {
    e.preventDefault();
    if (product.qty)
      dispatch({
        type: "ADD_PRODUCT",
        payload: { product: product },
      });
    setAdded(true);
    setTimeout(() => {
      setAdded(false);
    }, 1000);
  };
  return (
    product &&
    breadcrumbs && (
      <>
        <BreadcrumbNav breadcrumbs={breadcrumbs} name={product.title} />

        <div className="flex flex-wrap flex-row lg:flex-nowrap items-start gap-8 mt-10 justify-center md:justify-start">
          {/* Image gallery */}
          <div className="rounded-xl overflow-hidden md:max-w-lg aspect-1 flex-shrink">
            <img
              src={product.image ? product.image : defaultImg}
              alt=""
              className="w-full h-full object-center object-cover max-w-sm"
            />
          </div>

          {/* Product info */}
          <div className="max-w-2xl w-full md:w-fit gap-6 px-5 sm:px-0 lg:max-w-xl lg:grid lg:grid-cols-2 lg:grid-rows-[auto,1fr,1fr]">
            {/* <h1 className="text-2xl mb-5 lg:mb-0 font-semibold tracking-tight text-gray-900 sm:text-3xl">
              {product.title}
            </h1> */}

            {/* Options */}
            <div className="flex flex-col sm:items-center gap-4 lg:row-span-3 md:border-l md:border-gray-200 md:pl-8 self-center sm:flex-row sm:justify-end md:flex-col md:items-start">
              <p className="text-xl md:text-2xl text-gray-900 text-right sm:text-left">
                {product.price} ₽
              </p>
              {product.qty ? (
                <button
                  type="submit"
                  className={
                    "basis-0 w-min-fit mx-0 bg-accent border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium whitespace-nowrap text-white hover:opacity-80 gap-1" +
                    (isAdded ? " hover:opacity-100 hover:cursor-default" : "")
                  }
                  onClick={(e) => {
                    !isAdded ? addToCart(e) : e.preventDefault();
                  }}
                >
                  {isAdded ? (
                    <>
                      Добавлено <CheckIcon className="h-5" />
                    </>
                  ) : (
                    <>
                      Добавить в
                      <ShoppingBagIcon className="h-5" />
                    </>
                  )}
                </button>
              ) : (
                <span
                  className="basis-0 w-min-fit mx-auto sm:mx-0 bg-gray-600 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium whitespace-nowrap text-white 
                hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:cursor-not-allowed"
                >
                  Нет в наличии
                </span>
              )}
            </div>

            <div className="lg:col-start-1 lg:col-span-1">
              {/* Description and details */}
              <div>
                <h3 className="sr-only">Описание</h3>

                <p className="text-base text-gray-900 py-6 lg:my-0">
                  {product.description}
                </p>
              </div>

              {product.composition.length ? (
                <>
                  <h3 className="text-sm font-medium text-gray-900">Состав</h3>

                  <div className="mt-4">
                    <ul className="pl-4 list-disc text-sm space-y-2">
                      {product.composition.map((highlight) => (
                        <li key={highlight} className="text-gray-400">
                          <span className="text-gray-600">{highlight}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </>
    )
  );
}
