import axios from "axios";
import React, { useEffect, useState, useReducer } from "react";
import Reducer from "./Reducer";

let initialState = {
  categories: [],
  pages: [
    { name: "Услуги", href: "/services" },
    { name: "Оплата и доставка", href: "/payment" },
    { name: "Контакты", href: "/contacts" },
  ],
  cart: [],
  totalSum: 0,
};

export const StoreContext = React.createContext();

export default function Store({ children }) {
  const [render, setRender] = useState(false);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(process.env.REACT_APP_SERVER_URL + "/api/v2/categories")
        .then((response) => {
          // formatting categories
          initialState.categories = response.data.data.map(
            ({ _id, title, image, subCats, description }) => ({
              id: _id,
              name: title,
              items: subCats,
              img: image,
              description: description,
            })
          );
          setFetched(true);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchData();
  }, []);

  useEffect(() => {
    const setCart = async (cart) => {
      const localCart = cart.split(", ").map((str) => JSON.parse(str));
      await axios
        .get(process.env.REACT_APP_SERVER_URL + "/api/v2/products", {
          params: { ids: localCart.map(({ id }) => id) },
        })
        .then((response) => {
          var totalSum = 0;
          initialState.cart = response.data.data
            .map((product) => {
              const qty = localCart.find(({ id }) => id === product._id).qty;
              if (qty) {
                totalSum +=
                  product.price *
                  qty *
                  (1 - (product.discount ? product.discount / 100 : 0));
                return {
                  ...product,
                  qty: qty,
                };
              }
              return null;
            })
            .filter((value) => value);
          initialState.totalSum = totalSum;
        })
        .catch((err) => {});
      window.localStorage.setItem(
        "cart",
        initialState.cart
          .map(({ _id, qty, price, discount }) =>
            JSON.stringify({
              id: _id,
              qty: qty,
              price: price,
              discount: discount,
            })
          )
          .join(", ")
      );
      setRender(true);
    };
    if (fetched) {
      if (window.localStorage.getItem("cart"))
        setCart(window.localStorage.getItem("cart"));
      else setRender(true);
    }
  }, [fetched]);

  return render ? (
    <StoreProvider initialState={initialState}>{children}</StoreProvider>
  ) : (
    <></>
  );
}

function StoreProvider({ children, initialState }) {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <StoreContext.Provider value={[state, dispatch]}>
      {children}
    </StoreContext.Provider>
  );
}
