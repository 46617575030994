import React from "react";

function BreadcrumbNav({ breadcrumbs, name }) {
  return (
    <nav aria-label="Breadcrumb">
      <ol className="mx-auto flex items-end space-x-2 pl-5 sm:pl-0 py-6 leading-none whitespace-nowrap flex-wrap list-none">
        {breadcrumbs.map((breadcrumb) => (
          <li key={breadcrumb.key}>
            <div className="flex items-center">
              <a
                href={breadcrumb.href}
                className="mr-2 text-sm font-medium text-gray-900 hover:text-gray-700"
              >
                {breadcrumb.name}
              </a>
              <svg
                width={16}
                height={20}
                viewBox="0 0 16 20"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="w-4 h-5 text-gray-300"
              >
                <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
              </svg>
            </div>
          </li>
        ))}
        <li>
          <h2
            aria-current="page"
            className="text-xl -mb-[0.125rem] sm:mb-0 font-semibold tracking-tight text-gray-900 sm:text-[1.5rem]"
          >
            {name}
          </h2>
        </li>
      </ol>
    </nav>
  );
}

export default BreadcrumbNav;
