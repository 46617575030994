import React, { useContext, useState } from "react";
import {
  ShoppingCartIcon,
  PlusIcon,
  CheckIcon,
} from "@heroicons/react/outline";
import { StoreContext } from "./Store";

export const AddButton = ({ classN, addProduct, isAdded }) => {
  const cart = (
    <div className="peer-hover:opacity-0">
      <ShoppingCartIcon className="w-7 h-7" />
    </div>
  );
  const plus = (
    <div className="opacity-0 peer-hover:opacity-100">
      <PlusIcon className="w-7 h-7" />
    </div>
  );
  const added = (
    <div className="">
      <CheckIcon className="w-7 h-7" />
    </div>
  );
  const [cartIcon, setCart] = useState(cart);
  return (
    <div className={classN}>
      <button
        className="relative group z-20 text-white p-3"
        onMouseEnter={() => setTimeout(() => setCart(plus), 100)}
        onMouseLeave={() => setTimeout(() => setCart(cart), 100)}
        onClick={(e) => {
          !isAdded ? addProduct(e) : e.preventDefault();
        }}
      >
        <span className="absolute inset-0 peer z-10"></span>
        <div
          className={`absolute inset-0 rounded-full bg-accent -z-10 ${
            isAdded ? "" : "sm:peer-hover:scale-110"
          }`}
        ></div>
        {isAdded ? added : cartIcon}
      </button>
    </div>
  );
};
