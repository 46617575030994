import React, { useContext, useState } from "react";
import { AddButton } from "./AddButton";
import { StoreContext } from "./Store";
import defaultImg from "../img/defaultImg.png";

export default function ProductList({ products, filter }) {
  return (
    <>
      <div className="mx-auto w-full px-5 sm:px-0 flex justify-center">
        <div className="grid grid-cols-1 max-w-sm sm:max-w-none gap-y-10 gap-x-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:gap-x-8 ">
          {products.map((product) => (
            <ProductTile product={product} />
          ))}
        </div>
      </div>
    </>
  );
}

function ProductTile({ product }) {
  const [state, dispatch] = useContext(StoreContext);
  const [isAdded, setAdded] = useState(false);
  function addToCart(e) {
    e.preventDefault();
    dispatch({
      type: "ADD_PRODUCT",
      payload: { product: product },
    });
    setAdded(true);
    setTimeout(() => {
      setAdded(false);
    }, 1000);
  }
  return (
    <>
      <div
        key={product._id}
        className={"relative group" + (!product.qty ? " opacity-60" : "")}
      >
        <div className="w-full min-h-80 aspect-1 rounded-md overflow-hidden bg-gray-200 group-hover:opacity-70">
          <img
            src={product.image ? product.image : defaultImg}
            alt={product.title}
            className="w-full h-full max-w-xs object-center object-cover"
          />
        </div>
        <h3 className="text-xl text-gray-800 font-semibold overflow-hidden">
          <a
            href={"/product/" + product._id}
            target="_self"
            className="group-hover:text-pink w-full"
          >
            <span aria-hidden="true" className="absolute inset-0" />
            <span className="whitespace-nowrap">{product.title}</span>
          </a>
        </h3>
        {product.qty ? (
          <>
            <p className="text-lg text-gray-900">{product.price} ₽</p>
            <AddButton
              classN="absolute bottom-4 lg:bottom-5 -right-4"
              addProduct={addToCart}
              isAdded={isAdded}
            />
          </>
        ) : (
          <p className="text-lg text-gray-900">Нет в наличии</p>
        )}
      </div>
    </>
  );
}
