import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import ProductList from "../../Components/ProductList";
import ProductInfo from "./ProductInfo";
import axios from "axios";
import Logo from "../../Components/Logo";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

const Product = () => {
  const [products, setProducts] = useState("");
  const [fetching, setFetching] = useState(true);
  const [product, setProduct] = useState("");
  const [notFound, setNotFound] = useState(false);
  let params = useParams();
  const productId = params.productId;

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(process.env.REACT_APP_SERVER_URL + `/api/v2/products/${productId}`)
        .then((response) => {
          setProduct({ ...response.data });
          category = {
            category: response.data.category,
            _id: { $ne: productId },
            qty: { $gt: 0 },
          };
        })
        .catch((err) => {
          setNotFound(true);
        });

      category &&
        axios
          .get(process.env.REACT_APP_SERVER_URL + "/api/v2/products", {
            params: { filter: category, limit: 4 },
          })
          .then((response) => {
            setProducts(response.data.data);
            setFetching(false);
          })
          .catch((err) => {
            console.log(err.response.data.error);
            setFetching(false);
          });
    };
    var category = "";
    fetchData();
  }, []);
  return (
    <div className="mainWrapper">
      <div className="fixed w-full z-50">
        <Navbar />
      </div>
      {!notFound ? (
        <div className="bodywrapper">
          {product && <ProductInfo product={product} />}
          {fetching ? (
            <Logo className="flex w-full justify-center animate-pulse mt-8" />
          ) : (
            products && (
              <>
                <h2 className="text-xl sm:text-2xl tracking-tight text-gray-900 mt-16 px-5 sm:px-0 mb-4">
                  Другие товары категории:
                </h2>
                <ProductList products={products} />
              </>
            )
          )}
        </div>
      ) : (
        <div className="bodywrapper pt-20">
          <h3 className="text-2xl font-semibold mb-4">Товар не найден</h3>
          <div className="mt-10 flex justify-center items-center">
            <div className="text-lg flex flex-col items-center rounded-lg bg-neutral px-4 py-6 shadow-md">
              <ExclamationCircleIcon className="w-10 m-2 text-gray-500" />
              Данного товара не существует :(
              <a
                href="/"
                className="text-pink hover:text-accent italic underline hover:cursor-pointer"
              >
                Вернуться на главную
              </a>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Product;
