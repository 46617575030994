import React, { useContext } from "react";
import { useState } from "react";
import { MenuIcon } from "@heroicons/react/outline";
import Logo from "../../Components/Logo";
import Cart from "../../Components/Cart";
import MobileNav from "../../Components/MobileNav";
import { StoreContext } from "../../Components/Store";
import { Link } from "react-router-dom";
import GlobalCoundown from "../../Components/GlobalCoundown";
import HeaderPromo from "../../Components/HeaderPromo";

export default function Navbar() {
  const [open, setOpen] = useState(false);
  const [state, dispatch] = useContext(StoreContext);
  const { categories, pages } = state;

  return (
    <div className="bg-white w-screen">
      {/* <GlobalCoundown /> */}
      {/* <HeaderPromo /> */}

      {/* Mobile menu */}
      <MobileNav isOpen={open} setIsOpen={setOpen} />

      <header className="relative bg-white z-50">
        <nav
          aria-label="Top"
          className="max-w-7xl mx-auto px-5 sm:px-16 lg:px-20"
        >
          <div className="border-b border-gray-200">
            <div className="h-16 flex items-center">
              <button
                type="button"
                className="bg-white py-2 rounded-md text-gray-400 md:hidden"
                onClick={() => setOpen(true)}
              >
                <MenuIcon className="h-6 w-6 mr-4" aria-hidden="true" />
              </button>

              {/* Logo */}
              <span className="flex items-center gap-2">
                <Logo className="flex-shrink-0 w-8 md:w-auto" />
                <Link
                  to="/"
                  className="font-['Taurus'] translate-y-[7%] self-center text-2xl sm:hidden whitespace-nowrap text-pink"
                >
                  La Florida
                </Link>
              </span>

              <div className="hidden md:ml-8 md:self-stretch h-full md:flex space-x-8">
                {pages.map((page) => (
                  <a
                    key={page.name}
                    href={page.href}
                    className="flex items-center font-medium  hover:text-pink cursor-pointer whitespace-nowrap"
                  >
                    {page.name}
                  </a>
                ))}
                {/* <a
                  href="/deliveryinfo"
                  className="flex items-center hover:text-pink -mb-1 text-accent font-[Taurus] text-xl cursor-pointer whitespace-nowrap animate-pulse"
                >
                  8 Марта
                </a> */}
              </div>

              <div className="ml-auto flex items-center">
                {/* Cart */}
                <Cart />
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}
