import React, { useContext, useEffect, useRef, useState } from "react";
import { CartList } from "../../Components/Cart";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import { StoreContext } from "../../Components/Store";
import "dayjs/locale/ru";
import "react-dadata/dist/react-dadata.css";
import {
  AddressFieldset,
  CustomerFieldset,
  PickupAddressFieldset,
  PickupTimeFieldset,
  RecipientFieldset,
  shops,
} from "./Components";
import { Checkbox, ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  CashIcon,
  CreditCardIcon,
  ExclamationCircleIcon,
  OfficeBuildingIcon,
  TruckIcon,
} from "@heroicons/react/outline";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import uuid from "uuidv4";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import * as yup from "yup";
import { useFormik } from "formik";
import { useCountdown } from "../../Components/hooks/useCountdown";

var isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(timezone);
dayjs.tz.setDefault("Russia/Ekaterinburg");
dayjs.locale("ru");
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

function Checkout() {
  const [state, dispatch] = useContext(StoreContext);
  const [show, setShow] = useState(false);
  const [delivery, setDelivery] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isShipping, setShipping] = useState(false);
  const [payment, setPayment] = useState(false);
  const [ppAgreement, setPP] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (state.cart.length && !show) setShow(true);
    else if (show && !state.cart.length) setShow(false);
  }, [state.cart]);

  async function paymentProcess(values) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
      },
    };
    const request = {
      uuid: uuid(),
      products: state.cart.map(({ _id, qty, title }) => ({ _id, qty, title })),
      totalSum: state.totalSum,
      delivery: typeof delivery === "number" ? delivery : 0,
      payment: isShipping || payment,
      ...values,
    };
    await axios
      .post(
        process.env.REACT_APP_SERVER_URL + "/api/v2/orders/",
        request,
        config
      )
      .then((response) => {
        if (response.data.data) {
          window.open(
            response.data.data.confirmation.confirmation_url,
            "_self"
          );
        } else {
          navigate("/confirmed");
        }
      })
      .catch((err) => {
        setError(err);
        setTimeout(() => {
          setError("");
        }, 3000);
      });
  }

  // потом закомментить

  // const [checkoutOpen, setCheckout] = useState(true);

  // const [days, hours, minutes, seconds] = useCountdown(
  //   new Date(Date.UTC(2023, 2, 5, 19))
  // );

  // useEffect(() => {
  //   if (days + hours + minutes + seconds < 0) setCheckout(false);
  // });

  return (
    <div className="mainWrapper">
      <div className="fixed w-full z-50">
        <Navbar />
      </div>
      <div className="bodywrapper">
        {/* {checkoutOpen ? (
          <> */}
        <h3 className="text-2xl font-semibold  mb-4">Оформление заказа</h3>
        {show ? (
          <div className="flex flex-row flex-wrap-reverse gap-8 items-end justify-center lg:justify-between static">
            <CheckoutForm
              submitFunction={paymentProcess}
              setDelivery={setDelivery}
              setLoading={setLoading}
              loading={loading}
              error={error}
              isShipping={isShipping}
              setShipping={setShipping}
              payment={payment}
              setPayment={setPayment}
              ppAgreement={ppAgreement}
              setPP={setPP}
            />

            <div className="w-full sm:min-w-[25rem] grow md:w-1/3 max-w-md md:max-w-sm lg:sticky top-20">
              <h4 className="text-xl mb-2 font-semibold">
                Корзина ({state.cart.length})
              </h4>
              <CartList delivery={delivery} />
              <div className="hidden lg:block">
                {!isShipping && (
                  <div className="flex flex-col gap-2 mb-4">
                    <span className="font-bold text-xl">Способ оплаты:</span>
                    <ToggleButtonGroup
                      value={payment}
                      exclusive
                      onChange={(e, value) => {
                        if (value !== null) setPayment(value);
                      }}
                      size="medium"
                      className="mx-auto"
                    >
                      <ToggleButton
                        value={false}
                        className="capitalize text-base flex flex-row gap-1 items-center"
                      >
                        <CashIcon className="h-6" />
                        При получении
                      </ToggleButton>
                      <ToggleButton
                        value={true}
                        className="capitalize text-base flex flex-row gap-1 items-center"
                      >
                        <CreditCardIcon className="h-6" />
                        Онлайн
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                )}
                <div className="flex items-start">
                  <Checkbox
                    value={ppAgreement}
                    onChange={(e) => setPP(e.target.value)}
                    required={true}
                    form={"checkout-form"}
                  />
                  <div className="w-full text-center my-2">
                    Оформляя заказ, вы соглашаетесь с{" "}
                    <a
                      href="/privacy_policy.pdf"
                      target="_blank"
                      className="text-pink hover:text-accent"
                    >
                      политикой обработки персональных данных
                    </a>
                  </div>
                </div>
                <button
                  type="submit"
                  form="checkout-form"
                  className="bg-gray-600 rounded-md py-2 px-4 text-white hover:bg-accent w-full"
                >
                  {loading ? (
                    <svg
                      width="16px"
                      height="16px"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="w-6 h-6 animate-spin mx-auto"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13.917 7A6.002 6.002 0 0 0 2.083 7H1.071a7.002 7.002 0 0 1 13.858 0h-1.012z"
                      />
                    </svg>
                  ) : error ? (
                    error
                  ) : !isShipping && !payment ? (
                    "Оформить"
                  ) : (
                    "Перейти к оплате"
                  )}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-10 flex justify-center items-center">
            <div className="text-lg flex flex-col items-center rounded-lg bg-neutral px-4 py-6 shadow-md">
              <ExclamationCircleIcon className="w-10 m-2 text-gray-500" />В
              корзине пусто, посмотрите что-нибудь в{" "}
              <a
                href="/products/all"
                className="text-pink hover:text-accent italic underline hover:cursor-pointer"
              >
                каталоге
              </a>
            </div>
          </div>
        )}
        {/* </>
        ) : (
          <div className="mt-10 flex justify-center items-center">
            <div className="text-lg flex flex-col items-center rounded-lg bg-neutral px-4 py-6 shadow-md">
              <ExclamationCircleIcon className="w-10 m-2 text-gray-500" />
              Заказы на сайте не принимаются, звоните{" "}
              <a
                href="/contacts"
                className="text-pink hover:text-accent italic underline hover:cursor-pointer"
              >
                по телефону
              </a>
            </div>
          </div>
        )} */}
      </div>
      <Footer />
    </div>
  );
}

const validationSchema = yup.object({
  fullName: yup.string("Введите имя").required("* Обязательно к заполнению"),
  phoneNumber: yup
    .string("Введите телефон")
    .matches(
      /^((8|\+7)[-]?)?(\(?\d{3}\)?[-]?)?[\d\- ]{11}$/,
      "Введите корректный номер"
    )
    .required("* Обязательно к заполнению"),
  email: yup.string().email("Введите корректный адрес"),
  isShipping: yup.bool().required(),
  shippingInfo: yup.object().when("isShipping", {
    is: true,
    then: yup
      .object({
        address: yup.object({
          value: yup.string().required("* Обязательно к заполнению"),
          data: yup.object({
            house: yup.string().required("* Обязательно к заполнению"),
            geo_lat: yup.number().required(),
            geo_lon: yup.number().required(),
          }),
        }),
        isShipToAnother: yup.boolean(),
        recipInfo: yup.object().when("isShipToAnother", {
          is: true,
          then: yup.object({
            name: yup.string().required("* Обязательно к заполнению"),
            phone: yup
              .string()
              .matches(
                /^((8|\+7)[-]?)?(\(?\d{3}\)?[-]?)?[\d\- ]{11}$/,
                "Введите корректный номер"
              )
              .required("* Обязательно к заполнению"),
          }),
        }),
      })
      .noUnknown(false),
    date: yup
      .date("Введите дату доставки")
      .required("* Обязательно к заполнению"),
    time: yup
      .string("Введите время доставки")
      .required("* Обязательно к заполнению"),
  }),
  pickUpInfo: yup.object().when("isShipping", {
    is: false,
    then: yup.object({
      shop: yup
        .string("Выберите пункт самовывоза")
        .required("* Выберите пункт самовывоза"),
      date: yup.date("Выберите дату").required("* Обязательно к заполнению"),
      time: yup.string("Выберите время").required("* Обязательно к заполнению"),
    }),
  }),
});

const CheckoutForm = ({
  submitFunction,
  setDelivery,
  setLoading,
  loading,
  error,
  isShipping,
  setShipping,
  payment,
  setPayment,
  ppAgreement,
  setPP,
}) => {
  const formik = useFormik({
    initialValues: {
      fullName: "",
      phoneNumber: "",
      email: "",
      isShipping: false,
      shippingInfo: {
        address: { value: "", data: { house: "", geo_lat: "", geo_lon: "" } },
        date: dayjs()
          .add(
            25 - shops[0].workHours.weekRange[dayjs().isoWeekday() - 1][1],
            "hours"
          )
          .hour(0)
          .minute(0)
          .second(0),
        time: "",
        isShipToAnother: false,
        comment: "",
        entrance: "",
        flat: "",
        floor: "",
        intercom: "",
        recipInfo: {
          name: "",
          phone: "",
          note: "",
        },
      },
      pickUpInfo: {
        shop: "",
        date: dayjs().hour(0).minute(0),
        time: "",
      },
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const formCIref = useRef();
  const formSIref = useRef();
  const formSIRref = useRef();
  const formPIref = useRef();
  const formPITref = useRef();

  async function handleSubmit(values) {
    setLoading(true);
    var data = {
      ...values,
      shippingInfo: {
        ...values.shippingInfo,
        address: values.shippingInfo.address.value
          ? values.shippingInfo.address.value
          : "",
      },
      pickUpInfo: {
        ...values.pickUpInfo,
        address: values.pickUpInfo.shop
          ? shops.find(({ id }) => id === values.pickUpInfo.shop).address
          : "",
      },
    };

    if (values.isShipping) {
      data.deliveryInfo = data.shippingInfo;
      delete data["pickUpInfo"];
      delete data["shippingInfo"];
    } else {
      data.deliveryInfo = data.pickUpInfo;
      delete data["shippingInfo"];
      delete data["pickUpInfo"];
    }

    data.deliveryInfo.date = new Date(data.deliveryInfo.date);

    // window.alert(JSON.stringify(data));

    await submitFunction(data);

    setLoading(false);
  }

  useEffect(() => {
    setShipping(formik.values.isShipping);
  }, [formik.values.isShipping]);

  return (
    <form
      className="grid grow gap-5 max-w-md min-w-[20rem] md:max-w-[35rem] w-full sm:w-1/3"
      id="checkout-form"
      onSubmit={(e) => {
        e.preventDefault();
        if (ppAgreement) formik.handleSubmit();
      }}
    >
      <h4 className="text-xl w-fit font-semibold">Контактные данные</h4>
      <CustomerFieldset formik={formik} refId={formCIref} />
      <ToggleButtonGroup
        value={formik.values.isShipping}
        exclusive
        onChange={(e, value) => {
          if (value !== null)
            formik.handleChange({
              target: { name: "isShipping", value: value },
            });
        }}
        size="medium"
        className="mx-auto"
      >
        <ToggleButton
          value={false}
          className="capitalize text-base flex flex-row gap-1 items-center"
        >
          <OfficeBuildingIcon className="h-6" />
          Самовывоз
        </ToggleButton>
        <ToggleButton
          value={true}
          className="capitalize text-base flex flex-row gap-1 items-center"
        >
          <TruckIcon className="h-6" />
          Доставка
        </ToggleButton>
      </ToggleButtonGroup>
      {formik.values.isShipping ? (
        <>
          <AddressFieldset
            formik={formik}
            refId={formSIref}
            setDelivery={setDelivery}
          />
          {formik.values.shippingInfo.isShipToAnother && (
            <RecipientFieldset formik={formik} refId={formSIRref} />
          )}
        </>
      ) : (
        !setDelivery(0) && (
          <>
            <PickupAddressFieldset formik={formik} refId={formPIref} />
            {formik.values.pickUpInfo.shop && (
              <PickupTimeFieldset
                formik={formik}
                refId={formPITref}
                shopId={formik.values.pickUpInfo.shop}
              />
            )}
          </>
        )
      )}
      <div className="lg:hidden">
        {!isShipping && (
          <div className="flex flex-col gap-2 mb-4">
            <span className="font-bold text-xl">Способ оплаты:</span>
            <ToggleButtonGroup
              value={payment}
              exclusive
              onChange={(e, value) => {
                if (value !== null) setPayment(value);
              }}
              size="medium"
              className="mx-auto"
            >
              <ToggleButton
                value={false}
                className="capitalize text-base flex flex-row gap-1 items-center"
              >
                <CashIcon className="h-6" />
                При получении
              </ToggleButton>
              <ToggleButton
                value={true}
                className="capitalize text-base flex flex-row gap-1 items-center"
              >
                <CreditCardIcon className="h-6" />
                Онлайн
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        )}
        <div className="flex items-start">
          <Checkbox
            value={ppAgreement}
            onChange={(e) => setPP(e.target.value)}
          />
          <div className="w-full text-center my-2">
            Оформляя заказ, вы соглашаетесь с{" "}
            <a
              href="/privacy_policy.pdf"
              target="_blank"
              className="text-pink hover:text-accent"
            >
              политикой обработки персональных данных
            </a>
          </div>
        </div>
        <button
          type="submit"
          form="checkout-form"
          className="bg-gray-600 rounded-md py-2 px-4 text-white hover:bg-accent w-full"
        >
          {loading ? (
            <svg
              width="16px"
              height="16px"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="w-6 h-6 animate-spin mx-auto"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.917 7A6.002 6.002 0 0 0 2.083 7H1.071a7.002 7.002 0 0 1 13.858 0h-1.012z"
              />
            </svg>
          ) : error ? (
            error
          ) : !isShipping && payment ? (
            "Перейти к оплате"
          ) : (
            "Оформить"
          )}
        </button>
      </div>
    </form>
  );
};

export default Checkout;
