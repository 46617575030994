import React from "react";
import Footer, { SocLinks } from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import { YMaps, Map, Placemark, ZoomControl } from "react-yandex-maps";
import { shops } from "../Checkout/Components";
import icon from "../../img/icon.svg";
import {
  LocationMarkerIcon,
  MailIcon,
  PhoneIcon,
  XIcon,
} from "@heroicons/react/outline";

export class CustomTemplate extends React.Component {
  constructor({ chooseShop }) {
    super({ chooseShop });

    this.state = { template: null, ymaps: null, panel: null };

    this.onLoad = (ymaps) => {
      this.setState({ ymaps: ymaps });
    };

    this.createTemplate = (id) => {
      var Chips = this.state.ymaps.templateLayoutFactory.createClass(
        `<div class="w-9 h-9 relative block -mt-4 -ml-4"><img class="placemark border-pink w-full h-full object-contain bg-neutral border-4 rounded-full p-1" id=${
          "placemark-" + id
        } src=${icon}></img><span class="absolute text-xs whitespace-nowrap top-1/2 -translate-y-1/2 right-2 translate-x-full bg-white border border-gray-700 font-semibold py-1 pl-[0.85rem] pr-2 rounded-r-full -z-10">$[properties.iconCaption]</span></div>`,
        {
          build: function () {
            Chips.superclass.build.call(this);

            if (!this.inited) {
              this.inited = true;
              this.getData().geoObject.events.add(
                "click",
                function () {
                  Array.from(
                    document.querySelectorAll(".placemark.border-accent")
                  ).forEach(function (el) {
                    el.classList.remove("border-accent");
                    el.classList.remove("scale-125");
                    el.classList.add("border-pink");
                  });
                  var element = document.getElementById("placemark-" + id);
                  element.classList.add("border-accent");
                  element.classList.add("scale-125");
                  element.classList.remove("border-pink");
                  Array.from(document.querySelectorAll(".panel")).forEach(
                    function (el) {
                      el.classList.remove("absolute");
                      el.classList.add("hidden");
                    }
                  );
                  var panel = document.getElementById("panel-" + id);
                  panel.classList.add("absolute");
                  panel.classList.remove("hidden");
                },
                this
              );
            }
          },
        }
      );

      const circleShape = {
        type: "Circle",
        coordinates: [0, 0],
        radius: 20,
      };
      return {
        iconLayout: Chips,
        iconShape: circleShape,
        hideIconOnBalloonOpen: false,
      };
    };
  }

  render() {
    return (
      <YMaps>
        <Map
          // Event handler was moved from onApiAvaliable on YMaps to onLoad on Map
          onLoad={this.onLoad}
          defaultState={{ center: [57.98, 56.23], zoom: 11 }}
          className="w-full grow sm:w-1/2 sm:min-w-[30rem] min-h-[25rem] shadow-lg relative"
          options={{
            maxZoom: 17,
            restrictMapArea: [
              [57.9, 56],
              [58.1, 56.5],
            ],
          }}
          // We are also loading `templateLayoutFactory` additionally to Map
          modules={["templateLayoutFactory"]}
        >
          {this.state.ymaps && (
            <>
              {shops.map((shop) => (
                <>
                  <Placemark
                    geometry={shop.coords}
                    properties={{ iconCaption: shop.address }}
                    options={this.createTemplate(shop.id)}
                    modules={["geoObject.addon.balloon"]}
                  />
                  <div
                    className="panel hidden bg-white top-0 h-full w-full min-w-fit md:w-60 sm:w-1/2 "
                    id={"panel-" + shop.id}
                  >
                    <div className="flex flex-col gap-2 rounded-md px-6 py-10 h-full">
                      <XIcon
                        className="absolute w-5 h-5 text-gray-600 right-3 top-4 hover:opacity-50 hover:cursor-pointer"
                        onClick={() => {
                          Array.from(
                            document.querySelectorAll(
                              ".placemark.border-accent"
                            )
                          ).forEach(function (el) {
                            el.classList.remove("border-accent");
                            el.classList.remove("scale-125");
                            el.classList.add("border-pink");
                          });
                          Array.from(
                            document.querySelectorAll(".panel")
                          ).forEach(function (el) {
                            el.classList.remove("absolute");
                            el.classList.add("hidden");
                          });
                        }}
                      />
                      <h4 className="font-semibold whitespace-nowrap flex gap-1 text-lg">
                        <LocationMarkerIcon className="w-4 inline-block text-gray-500" />
                        {shop.address}
                      </h4>
                      <table className="text-gray-600 w-fit">
                        {shop.workHours.weekRangeStr.map((el, index) => (
                          <tr>
                            <td className="text-pink pr-1 font-semibold text-right">
                              {el}:
                            </td>
                            <td>{shop.workHours.hoursStr[index]}</td>
                          </tr>
                        ))}
                      </table>
                      <div className="mt-auto text-gray-700 align-middle">
                        <a
                          href={"tel:" + shop.phoneNumber}
                          className="flex text-lg gap-1 hover:text-accent w-min  whitespace-nowrap"
                        >
                          <PhoneIcon className="w-4 text-accent inline-block" />
                          {shop.phoneNumber}
                        </a>
                        <span className="text-sm ml-5 text-gray-600">
                          Флорист
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ))}
              <ZoomControl />
            </>
          )}
        </Map>
      </YMaps>
    );
  }
}

const Contacts = () => {
  return (
    <div className="mainWrapper">
      <div className="fixed w-full z-50">
        <Navbar />
      </div>
      <div className="bodywrapper">
        <h2 className="text-2xl sm:text-3xl font-bold uppercase max-w-xs mb-4">
          Контакты
        </h2>
        <div className="rounded-lg w-full bg-neutral overflow-hidden shadow-lg flex flex-col sm:flex-row sm:flex-wrap ">
          <div className="grow w-full sm:w-40 p-6 md:p-10 min-w-fit">
            <h2 className="text-lg sm:text-xl font-bold max-w-xs mb-2">
              Основной телефон:
            </h2>
            <div className="flex flex-col gap-4 text-gray-700">
              <div className="">
                <a
                  href={"tel:+73422286000"}
                  className="flex text-lg gap-1 hover:text-accent w-min whitespace-nowrap"
                >
                  <PhoneIcon className="w-6 text-accent inline-block" />
                  +7 (342) 228-60-00
                </a>
                <span className="text-sm text-gray-600">Прием звонков:</span>
                <span className="text-sm ml-2 text-gray-600">
                  {shops[0].workHours.weekRangeStr.map((el, index) => (
                    <tr>
                      <td className="text-pink pr-1 font-semibold text-right">
                        {el}:
                      </td>
                      <td>{shops[0].workHours.hoursStr[index]}</td>
                    </tr>
                  ))}
                </span>
              </div>
              <a
                href={"tel:"}
                className="flex text-lg gap-1 hover:text-accent w-min whitespace-nowrap"
              >
                <MailIcon className="w-6 text-accent inline-block" />
                floraapk@mail.ru
              </a>
              <SocLinks className="flex flex-row gap-4 mt-4 mx-auto h-10 text-accent" />
            </div>
          </div>
          <CustomTemplate />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contacts;
