import React, { useContext, useState, useEffect } from "react";
import { StoreContext } from "../../Components/Store";
import { useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import ProductList from "../../Components/ProductList";
import Navbar from "../../Components/Navbar";
import FilterBlock from "./Components";
import axios from "axios";
import Logo from "../../Components/Logo";
import BreadcrumbNav from "../../Components/BreadcrumbNav";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function Products() {
  const [level, categoryId] = useParams().filter.split("=");
  const [{ categories }, dispatch] = useContext(StoreContext);
  const [notFound, setNotFound] = useState(false);

  var data = {
    breadcrumbs: [],
    category: "Все товары",
  };

  var category = {};

  if (level !== "all") {
    if (level === "0") {
      data = categories
        .map(({ name }) => {
          return name === categoryId
            ? {
                breadcrumbs: [
                  {
                    name: "Все товары",
                    href: "/products/all",
                    key: "all",
                  },
                ],
                category: name,
              }
            : null;
        })
        .filter((value) => value)[0];

      category = {
        category: {
          $in: categories.filter(({ name }) => categoryId === name).length
            ? categories.filter(({ name }) => categoryId === name)[0].items
            : [],
        },
      };
    } else {
      data = categories
        .map(({ name, items }) => {
          const item = items.find((value) => value === categoryId);
          return item
            ? {
                breadcrumbs: [
                  {
                    name: "Все товары",
                    href: "/products/all",
                    key: "all",
                  },
                  {
                    name: name,
                    href: `/products/0=${name}`,
                    key: name,
                  },
                ],
                category: item,
              }
            : null;
        })
        .filter((value) => value)[0];
      category = { category: data.category };
    }
  }

  const [products, setProducts] = useState([]);
  const [fetching, setFetching] = useState();
  const [properties, setProps] = useState({
    category: [],
    price: [],
    composition: [],
  });

  async function fetchFilteredData(filter) {
    setFetching(true);
    await axios
      .get(process.env.REACT_APP_SERVER_URL + "/api/v2/products", {
        params: { filter: { ...category, ...filter } },
      })
      .then((response) => {
        setProducts(response.data.data.sort((a, b) => b.qty - a.qty));
        setFetching(false);
      })
      .catch((err) => {
        console.log(err.response.data.error);
      });
  }

  useEffect(() => {
    fetchFilteredData();
  }, []);

  useEffect(() => {
    if (!properties.category.length)
      setProps((props) =>
        Object.fromEntries(
          Object.entries(props).map(([key, v]) => {
            let value = products
              .map((product) => product[key])
              .flat(1)
              .filter(onlyUnique);
            if (key === "price")
              value = [Math.min(...value), Math.max(...value)];
            return [key, value];
          })
        )
      );
  }, [products]);

  return (
    <div className="mainWrapper">
      <div className="fixed w-full z-50">
        <Navbar />
      </div>

      <div className="bodywrapper">
        <BreadcrumbNav breadcrumbs={data.breadcrumbs} name={data.category} />
        <div className="flex flex-col gap-6 md:items-start md:flex-row">
          {properties.category.length > 0 && (
            <FilterBlock
              fetchFilteredData={fetchFilteredData}
              properties={properties}
            />
          )}
          {fetching ? (
            <Logo className="flex w-full justify-center animate-pulse mt-8" />
          ) : products.length ? (
            <ProductList products={products} />
          ) : (
            <div className="w-full">
              <div className="text-lg flex flex-col items-center rounded-lg bg-neutral px-4 py-6 shadow-md">
                <ExclamationCircleIcon className="w-10 m-2 text-gray-500" />
                Товары не найдены :(
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Products;
