import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo';

const socs = [
    {
        svg: (
            <svg
                className="w-full h-full hover:text-pink"
                fill="currentColor"
                viewBox="2 2 20 20"
                aria-hidden="true"
            >
                <path
                    fill-rule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clip-rule="evenodd"
                />
            </svg>
        ),
        link: 'https://www.instagram.com/la_florida_perm/',
        name: 'instagram',
    },
    {
        svg: (
            <svg
                className="w-full h-full hover:text-[#277ef4]"
                fill="currentColor"
                viewBox="0 0 48 48"
                aria-hidden="true"
            >
                <path d="M0 23.04C0 12.1788 0 6.74826 3.37413 3.37413C6.74826 0 12.1788 0 23.04 0H24.96C35.8212 0 41.2517 0 44.6259 3.37413C48 6.74826 48 12.1788 48 23.04V24.96C48 35.8212 48 41.2517 44.6259 44.6259C41.2517 48 35.8212 48 24.96 48H23.04C12.1788 48 6.74826 48 3.37413 44.6259C0 41.2517 0 35.8212 0 24.96V23.04Z" />
                <path
                    d="M25.54 34.5801C14.6 34.5801 8.3601 27.0801 8.1001 14.6001H13.5801C13.7601 23.7601 17.8 27.6401 21 28.4401V14.6001H26.1602V22.5001C29.3202 22.1601 32.6398 18.5601 33.7598 14.6001H38.9199C38.0599 19.4801 34.4599 23.0801 31.8999 24.5601C34.4599 25.7601 38.5601 28.9001 40.1201 34.5801H34.4399C33.2199 30.7801 30.1802 27.8401 26.1602 27.4401V34.5801H25.54Z"
                    fill="#fafcfa"
                />
            </svg>
        ),
        link: 'https://vk.com/laflorida_flower_base',
        name: 'vk',
    },
    {
        svg: (
            <svg
                viewBox="10 10 220 220"
                className="w-full h-full hover:text-[#2fade1]"
            >
                <title>Telegram_logo</title>
                <rect
                    x="10"
                    y="10"
                    rx="55"
                    ry="55"
                    width="220"
                    height="220"
                    fill="currentColor"
                />
                <path
                    d="M81.229,128.772l14.237,39.406s1.78,3.687,3.686,3.687,30.255-29.492,30.255-29.492l31.525-60.89L81.737,118.6Z"
                    fill="#c8daea"
                />
                <path
                    d="M100.106,138.878l-2.733,29.046s-1.144,8.9,7.754,0,17.415-15.763,17.415-15.763"
                    fill="#a9c6d8"
                />
                <path
                    d="M81.486,130.178,52.2,120.636s-3.5-1.42-2.373-4.64c.232-.664.7-1.229,2.1-2.2,6.489-4.523,120.106-45.36,120.106-45.36s3.208-1.081,5.1-.362a2.766,2.766,0,0,1,1.885,2.055,9.357,9.357,0,0,1,.254,2.585c-.009.752-.1,1.449-.169,2.542-.692,11.165-21.4,94.493-21.4,94.493s-1.239,4.876-5.678,5.043A8.13,8.13,0,0,1,146.1,172.5c-8.711-7.493-38.819-27.727-45.472-32.177a1.27,1.27,0,0,1-.546-.9c-.093-.469.417-1.05.417-1.05s52.426-46.6,53.821-51.492c.108-.379-.3-.566-.848-.4-3.482,1.281-63.844,39.4-70.506,43.607A3.21,3.21,0,0,1,81.486,130.178Z"
                    fill="#fff"
                />
            </svg>
        ),
        link: '/',
        name: 'tg',
    },
    {
        svg: (
            <svg
                viewBox="0 0 370 370"
                className="w-full h-full hover:text-[#1bd741]"
            >
                <rect
                    x="0"
                    y="0"
                    rx="90"
                    ry="90"
                    width="370"
                    height="370"
                    fill="currentColor"
                />
                <svg x="45" y="40" fill="#fafcfa" viewBox="0 0 400 400">
                    <path
                        id="XMLID_469_"
                        d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
  c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
  c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
  c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
  c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
  c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
  c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
  c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
  c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
  C233.168,179.508,230.845,178.393,227.904,176.981z"
                    />
                    <path
                        id="XMLID_470_"
                        d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
  c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
  c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
   M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
  l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
  c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
  C276.546,215.678,222.799,268.994,156.734,268.994z"
                    />
                </svg>
            </svg>
        ),
        link: 'https://wa.me/+79194606111',
        name: 'wa',
    },
];

export const SocLinks = (props) => {
    return (
        <ul className={props.className}>
            {socs.map((soc) => (
                <a
                    className=" hover:cursor-pointer h-full"
                    target="_blank"
                    href={soc.link}
                    rel="noreferrer"
                >
                    {soc.svg}
                </a>
            ))}
        </ul>
    );
};

const Footer = () => {
    return (
        <footer className="bg-neutral mt-auto">
            <div className="max-w-7xl px-8 sm:px-16 lg:px-20 py-8 mx-auto">
                <div className="md:flex md:justify-between">
                    <div className="mb-6 md:mb-0">
                        <span className="flex items-center gap-2">
                            <Logo className="flex-shrink-0" />
                            <Link
                                to="/"
                                className="font-['Taurus'] translate-y-[7%] self-center text-3xl whitespace-nowrap text-pink"
                            >
                                La Florida
                            </Link>
                        </span>
                    </div>
                    <div className="grid grid-cols-2 gap-8 md:grid-cols-3">
                        <div className="hidden md:block"></div>
                        <div className="">
                            <h2 className="mb-6 text-sm font-semibold text-accent uppercase">
                                Интернет-магазин
                            </h2>
                            <ul className="text-gray-600">
                                <li className="mb-4">
                                    <a
                                        href="/products/all"
                                        className="hover:underline"
                                    >
                                        Каталог
                                    </a>
                                </li>
                                <li className="mb-4">
                                    <a
                                        href="services"
                                        className="hover:underline"
                                    >
                                        Услуги
                                    </a>
                                </li>
                                <li className="mb-4">
                                    <a
                                        href="/payment"
                                        className="hover:underline"
                                    >
                                        Оплата и доставка
                                    </a>
                                </li>
                                <li className="mb-4">
                                    <a
                                        href="/deliveryinfo"
                                        className="hover:underline"
                                    >
                                        Доставка к 8 марта
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-accent uppercase">
                                Компания
                            </h2>
                            <ul className="text-gray-600">
                                <li className="mb-4">
                                    <a
                                        href="/contacts"
                                        className="hover:underline"
                                    >
                                        Контакты
                                    </a>
                                </li>
                                <li className="mb-4">
                                    <a
                                        href="/privacy_policy.pdf"
                                        className="hover:underline"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Политика обработки персональных данных
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
                <div className="flex flex-col sm:flex-row items-center justify-between gap-2">
                    <span className="text-sm text-gray-500 sm:text-center">
                        © 2022{' '}
                        <a href="/" className="hover:underline">
                            LaFlorida
                        </a>
                        . Все права защищены.
                    </span>
                    <SocLinks className="flex flex-row gap-4 h-5 text-gray-500" />
                </div>
            </div>
        </footer>
    );
};

export default Footer;
