import React, { Fragment, useContext } from "react";
import { Dialog, Tab, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { StoreContext } from "./Store";
import { Link } from "react-router-dom";

export default function MobileNav({ isOpen, setIsOpen }) {
  const [state, dispatch] = useContext(StoreContext);
  const { categories, pages } = state;
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 flex md:hidden z-50"
        onClose={setIsOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
            <div className="px-4 pt-5 pb-2 flex">
              <button
                type="button"
                className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
                onClick={() => setIsOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>

            {/* Links */}
            <Tab.Group as="div" className="mt-2">
              <div className="border-b border-gray-200">
                <Tab.List className="-mb-px flex px-4 space-x-8">
                  <Tab
                    key="categories"
                    className="text-gray-900 border-accent
                        flex-1 whitespace-nowrap py-4 px-1 border-b-2 text-lg font-medium"
                  >
                    Каталог
                  </Tab>
                </Tab.List>
              </div>
              <Tab.Panels as={Fragment}>
                <Tab.Panel
                  key="categories"
                  className="pt-10 pb-8 px-4 space-y-10"
                >
                  {categories.map((category) => (
                    <div key={category.name}>
                      <a
                        id={`categories-${category.name}-heading-mobile`}
                        className="text-gray-900"
                        href={`/products/0=${category.name}`}
                      >
                        {category.name}
                      </a>
                      <ul
                        aria-labelledby={`categories-${category.name}-heading-mobile`}
                        className="mt-6 flex flex-col space-y-6"
                      >
                        {category.items.map((item) => (
                          <li key={item} className="flow-root">
                            <a
                              href={`/products/1=${item}`}
                              className="-my-3 p-2 block text-gray-500"
                            >
                              {item}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>

            <div className="border-t border-gray-200 py-6 px-4 space-y-6">
              {pages.map((page) => (
                <div key={page.name} className="flow-root">
                  <Link
                    to={page.href}
                    className="-m-2 p-2 block font-medium text-gray-900"
                  >
                    {page.name}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
