import React from "react";
import { useState, useContext } from "react";
import { MenuIcon } from "@heroicons/react/outline";
import Logo from "./Logo";
import Search from "./Search";
import Cart from "./Cart";
import MobileNav from "./MobileNav";
import FlyoutMenu from "./FlyoutMenu";
import { StoreContext } from "./Store";
import { Link } from "react-router-dom";
import GlobalCoundown from "./GlobalCoundown";
import HeaderPromo from "./HeaderPromo";

// const sections = [
//   {
//     id: "bouquet",
//     name: "Букеты",
//     items: [
//       { name: "Розы", href: "#" },
//       { name: "Монобукеты", href: "#" },
//       { name: "Смешанные", href: "#" },
//       { name: "Каркасные", href: "#" },
//       { name: "До 2500", href: "#" },
//     ],
//     href: "#",
//     img: temlateImg,
//   },
//   {
//     id: "houseplants",
//     name: "Комнатные растения",
//     items: [
//       { name: "Цветущие", href: "#" },
//       { name: "Декоративно-лиственные", href: "#" },
//       { name: "Кактусы", href: "#" },
//     ],
//     href: "#",
//     img: temlateImg,
//   },
//   {
//     id: "garden",
//     name: "Сад и огород",
//     items: [
//       { name: "Хвойники", href: "#" },
//       { name: "Многолетние", href: "#" },
//       { name: "Плодовые", href: "#" },
//     ],
//     href: "#",
//     img: temlateImg,
//   },
// ];

export default function Navbar() {
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [state, dispatch] = useContext(StoreContext);
  const { categories, pages } = state;
  const pathname = window.location.pathname;
  return (
    <div className="bg-white w-screen">
      {/* <GlobalCoundown /> */}
      {/* <HeaderPromo/> */}
      {/* Mobile menu */}
      <MobileNav isOpen={open} setIsOpen={setOpen} />

      <header className="relative bg-white z-50">
        <nav
          aria-label="Top"
          className="max-w-7xl mx-auto px-5 sm:px-16 lg:px-20"
        >
          <div className="border-b border-gray-200">
            <div className="h-16 flex items-center">
              <button
                type="button"
                className="bg-white py-2 rounded-md text-gray-400 md:hidden z-50"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6 mr-4" aria-hidden="true" />
              </button>

              {/* Logo */}
              <span className="flex items-center gap-2">
                <Logo className="flex-shrink-0 w-8 md:w-auto" />
                <a
                  href="/"
                  className="font-['Taurus'] translate-y-[7%]  self-center text-2xl md:hidden whitespace-nowrap text-pink"
                >
                  La Florida
                </a>
              </span>

              <div className="hidden md:ml-8 md:self-stretch h-full md:flex space-x-5 lg:space-x-8 z-50">
                <a
                  href="/"
                  className="flex items-center font-medium  hover:text-pink"
                >
                  Главная
                </a>
                <button
                  onClick={() => {
                    setIsOpen(true);
                    document.body.scroll("no");
                  }}
                  className={
                    "flex items-center font-medium  hover:text-pink" +
                    (isOpen || pathname.split("/")[1] === "products"
                      ? " text-accent border-b-2 border-b-accent mt-[2px] hover:border-b-pink"
                      : "")
                  }
                >
                  Каталог
                </button>
                {pages.map((page) => (
                  <a
                    key={page.name}
                    href={page.href}
                    className={
                      "flex items-center font-medium  hover:text-pink cursor-pointer whitespace-nowrap" +
                      (pathname === page.href && !isOpen
                        ? " text-accent border-b-2 border-b-accent mt-[2px] hover:border-b-pink"
                        : "")
                    }
                  >
                    {page.name}
                  </a>
                ))}
              </div>

              <FlyoutMenu isOpen={isOpen} setIsOpen={setIsOpen} />

              <div className="ml-auto flex items-center">
                {/* Search */}
                <Search />

                {/* Cart */}
                <Cart />
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}
