import React, { useEffect, useState } from "react";
import Carusel from "./Carusel";
import CategoryList from "./CategoryList";
import Footer from "../../Components/Footer";
import MainSearch from "./MainSearch";
import Navbar from "./NavBar";
import ProductList from "../../Components/ProductList";
import axios from "axios";
import Logo from "../../Components/Logo";
import { ChevronRightIcon } from "@heroicons/react/outline";

const Home = () => {
  return (
    <div className="mainWrapper">
      <div className="fixed w-full z-50">
        <Navbar />
      </div>
      <div className="bodywrapper px-0 sm:px-16 lg:px-20 pt-32 sm:pt-32">
        <MainSearch />
        <CategoryList />
        <Carusel />
        <div className="flex flex-row justify-between items-center mt-8 px-8 sm:px-0  mb-6">
          <h3 className="text-xl tracking-tight text-gray-900 sm:text-2xl leading-5">
            Популярные товары
          </h3>
          <a
            href="/products/all"
            className="text-gray-500 text-lg sm:text-xl hover:underline hover:text-pink leading-5 text-right whitespace-nowrap"
          >
            Смотреть все
            <ChevronRightIcon className="h-4 inline-block" />
          </a>
        </div>
        <MainProductList />
      </div>
      <Footer />
    </div>
  );
};

export default Home;

function MainProductList() {
  const [products, setProducts] = useState("");

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_SERVER_URL + "/api/v2/products", {
        params: { filter: { popular: true }, limit: 8 },
      })
      .then((response) => {
        setProducts(response.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.error);
      });
  }, []);
  return products ? (
    <ProductList products={products} />
  ) : (
    <Logo className="flex w-full justify-center animate-pulse mt-8" />
  );
}
