import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import mainImg from "../../img/pawel-czerwinski-lWBZ01XRRoI-unsplash.jpg";
import img1 from "../../img/linh-le-4ADrWAWYnT4-unsplash.jpg";
import img2 from "../../img/samantha-gades-x40Q9jrEVT0-unsplash.jpg";
import img3 from "../../img/kalei-de-leon-w1JoPXn2Hw4-unsplash.jpg";
import img4 from "../../img/element5-digital-z6i_UCBuu5Q-unsplash.jpg";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { RefreshIcon, CheckIcon } from "@heroicons/react/outline";

const tiles = [
  {
    id: 1,
    title: "Оформление интерьера растениями",
    description: `Подбор и оформление помещения живыми или искусственными цветами`,
    img: img1,
    alt: "",
    link: "#form",
    linkText: "Заказать",
  },
  {
    id: 2,
    title: "Оформление праздников, свадеб",
    description: `Праздничное оформление цветами помещений любой сложности.`,
    img: img2,
    alt: "",
    link: "#form",
    linkText: "Заказать",
  },
  {
    id: 3,
    title: "Посадка и пересадка растений",
    description: `Помощь в пересадке домашних и уличных растений, подбор грунта и удобрений, рекомендации по уходу за растением.`,
    img: img3,
    alt: "",
    link: "#form",
    linkText: "Заказать",
  },
  {
    id: 4,
    title: "Онлайн консультации",
    description: `Помощь в лечении ваших растений, рекомендации по уходу.`,
    img: img4,
    alt: "",
    link: "https://wa.me/+79194606111",
    linkText: "Написать",
  },
];

const validationSchema = yup.object({
  theme: yup.number().required(" "),
  fullName: yup.string("ФИО").required(" "),
  phone: yup
    .string(" ")
    .matches(/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/, " ")
    .required(" "),
  email: yup.string("Введите вашу почту").email(" ").required(),
});

const Services = () => {
  const [fetching, setFetching] = useState(false);
  const formik = useFormik({
    initialValues: {
      theme: "",
      fullName: "",
      phone: "",
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, resetForm) => {
      setFetching("Отправка");
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        };
        await axios
          .post(
            process.env.REACT_APP_SERVER_URL + "/api/v2/notificate/",
            {
              type: "feedback",
              contents: {
                ...values,
                title: tiles.find(({ id }) => id === values.theme).title,
              },
            },
            config
          )
          .then((res) => {
            setFetching("Успешно");
            setTimeout(() => {
              setFetching("");
              resetForm();
            }, 3000);
          })
          .catch((err) => {});
      } catch (error) {
        console.warn(error.message);
      }
    },
  });
  return (
    <div className="mainWrapper">
      <div className="fixed w-full z-50">
        <Navbar />
      </div>
      <div className="bodywrapper">
        <div
          className="rounded-lg min-h-[20rem] w-full mb-10 p-10"
          style={{
            backgroundImage: `url(${mainImg})`,
            backgroundPosition: "left",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <h2 className="text-2xl sm:text-3xl font-bold uppercase max-w-lg">
            Услуги цветочного оформления, пересадки цветов и онлайн-консультации
          </h2>
          <p className="my-10 text-xl text-gray-800 bg-white rounded-lg p-5 md:p-10 w-fit shadow-lg max-w-sm">
            <a
              className="hover:text-accent font-semibold hover:cursor-pointer"
              href="tel:+73422286000"
            >
              Позвоните
            </a>{" "}
            или{" "}
            <a
              className="hover:text-pink font-semibold hover:cursor-pointer"
              href="mailto:floraapk@mail.ru"
            >
              напишите
            </a>{" "}
            нам — мы всегда рады помочь!
          </p>
        </div>
        <ul className="flex gap-4 flex-wrap place-content-center mb-10">
          {tiles.map((tile) => (
            <li className="overflow-hidden rounded-lg max-w-xs shadow-lg relative w-64 grow">
              <img
                src={tile.img}
                alt={tile.alt}
                className="h-44 w-full object-cover"
              />
              <div className="p-4 mb-20">
                <h3 className="text-xl uppercase font-semibold mb-3">
                  {tile.title}
                </h3>
                <p className="text-lg leading-6">{tile.description}</p>
              </div>
              {tile.link === "#form" ? (
                <button
                  href={tile.link}
                  className="bg-gray-600 text-white rounded-md py-2 px-3 absolute bottom-4 left-4 hover:bg-accent"
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleChange({
                      target: { name: "theme", value: tile.id },
                    });
                    document.getElementById("form").scrollIntoView();
                  }}
                >
                  {tile.linkText}
                </button>
              ) : (
                <a
                  href={tile.link}
                  className="bg-gray-600 text-white rounded-md py-2 px-3 absolute bottom-4 left-4 hover:bg-accent"
                  rel="noreferrer"
                  target="_blank"
                >
                  {tile.linkText}
                </a>
              )}
            </li>
          ))}
        </ul>
        <div id="form">
          <h3 className="text-xl uppercase font-semibold mb-6">Форма связи</h3>
          <div className="flex gap-5 flex-wrap">
            <p className="text-lg w-80 grow">
              Оставьте заявку через форму и мы с вами свяжемся!
            </p>
            <form
              className="grid gap-3 w-full max-w-sm md:max-w-none md:w-fit mx-auto bg-gray-600 rounded-lg shadow-xl p-6 md:py-8 lg:py-10 lg:px-7"
              id="checkout-form"
              method="POST"
              onSubmit={formik.handleSubmit}
            >
              <FormControl size="small" variant="filled">
                <InputLabel>Тема обращения *</InputLabel>
                <Select
                  name="theme"
                  className="w-full sm:w-[38ch]"
                  value={formik.values.theme}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.theme && Boolean(formik.errors.theme)}
                >
                  {tiles.slice(0, -1).map(({ title, id }) => (
                    <MenuItem value={id} className="">
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                id="name-textfield"
                name="fullName"
                label="Имя *"
                variant="filled"
                size="small"
                className="grow"
                value={formik.values.fullName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.fullName && Boolean(formik.errors.fullName)
                }
              />
              <TextField
                id="phone-textfield"
                name="phone"
                label="Телефон *"
                variant="filled"
                size="small"
                className="grow"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
              />
              <TextField
                id="email-textfield"
                name="email"
                label="Почта *"
                variant="filled"
                size="small"
                className="grow"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
              <button
                type="submit"
                form="checkout-form"
                className="bg-accent rounded-md py-2 px-4 text-white hover:bg-neutral hover:text-gray-700 mt-2 md:mt-4 lg:mt-6 relative flex items-center justify-center gap-3"
              >
                {fetching ? (
                  fetching === "Отправка" ? (
                    <>
                      <RefreshIcon className="h-5 animate-spin -ml-5"></RefreshIcon>{" "}
                      Отправка
                    </>
                  ) : (
                    <>
                      <CheckIcon className="h-5 -ml-5"></CheckIcon> Успешно
                    </>
                  )
                ) : (
                  "Отправить"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Services;
