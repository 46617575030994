import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import Logo from "../../Components/Logo";
import ProductList from "../../Components/ProductList";

function Slide() {
  let params = useParams();
  const slideId = params.slideId;

  const [slide, setSlide] = useState("");
  const [products, setProducts] = useState([]);
  const [fetching, setFetching] = useState();

  async function fetchFilteredData(id) {
    setFetching(true);
    await axios
      .get(`https://sirius.laflorida.ru/api/v2/cards/${id}`)
      .then((response) => {
        setSlide(response.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.error);
      });
  }

  useEffect(() => {
    fetchFilteredData(slideId);
  }, []);

  useEffect(() => {
    slide &&
      axios
        .get(process.env.REACT_APP_SERVER_URL + "/api/v2/products", {
          params: { ids: slide.productList },
        })
        .then((response) => {
          setProducts(response.data.data);
          setFetching(false);
        })
        .catch((err) => {
          console.log(err.response.data.error);
        });
  }, [slide]);
  return (
    <div className="mainWrapper">
      <div className="fixed w-full z-50">
        <Navbar />
      </div>
      <div className="bodywrapper">
        {fetching ? (
          <Logo className="flex w-full justify-center animate-pulse mt-8" />
        ) : (
          <div className="flex flex-col gap-4">
            <h3 className="text-xl leading-tight">{slide.title}</h3>
            <div className="relative rounded-md overflow-hidden">
              <img
                src={slide.image}
                alt=""
                className="h-36 sm:max-h-24 w-full object-center object-cover group-hover:opacity-70"
              />
              <span className="absolute bg-white px-6 py-2 rounded-md max-w-xs h-3/4 top-[12.5%] left-[3%] right-[3%] sm:left-4">
                <div className="w-full h-full flex flex-col justify-evenly items-start">
                  <p>{slide.description}</p>
                </div>
              </span>
            </div>
            <ProductList products={products} />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Slide;
