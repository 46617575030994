import React, { useState } from "react";
import Slider from "@mui/material/Slider";
import { Checkbox, TextField } from "@mui/material";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";

const minDistance = 10;

export default function FilterBlock({ properties, fetchFilteredData }) {
  const [filter, setFilter] = useState({
    composition: [],
    categories: [],
    price: [properties.price[0], properties.price[1]],
    qty: { $exists: true },
  });
  const [openFilter, setOpenFilter] = useState(false);

  function submitFilter(e) {
    e.preventDefault();
    var normalizedFilter = {
      price: { $gte: filter.price[0], $lte: filter.price[1] },
      qty: filter.qty,
    };
    if (filter.composition.length !== 0)
      normalizedFilter = {
        ...normalizedFilter,
        composition: { $in: filter.composition },
      };
    if (filter.categories.length !== 0)
      normalizedFilter = {
        ...normalizedFilter,
        category: { $in: filter.categories },
      };

    fetchFilteredData(normalizedFilter);
  }

  function handleComposition(e) {
    const { checked, value } = e.target;
    let newFilter = filter;
    if (checked) newFilter.composition.push(value);
    else
      newFilter.composition = newFilter.composition.filter((e) => e !== value);
    setFilter(newFilter);
  }

  function handleCategory(e) {
    const { checked, value } = e.target;
    let newFilter = filter;
    if (checked) newFilter.categories.push(value);
    else newFilter.categories = newFilter.categories.filter((e) => e !== value);
    setFilter(newFilter);
  }

  const handlePrice = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      const { id, value } = event.target;
      if (isNaN(value)) return;
      if (id === "min-number") {
        setFilter((filter) => ({
          ...filter,
          price: [value, filter.price[1]],
        }));
      } else {
        setFilter((filter) => ({
          ...filter,
          price: [filter.price[0], value],
        }));
      }

      return;
    }

    if (activeThumb === 0) {
      setFilter((filter) => ({
        ...filter,
        price: [
          Math.min(newValue[0], filter.price[1] - minDistance),
          filter.price[1],
        ],
      }));
    } else {
      setFilter((filter) => ({
        ...filter,
        price: [
          filter.price[0],
          Math.max(newValue[1], filter.price[0] + minDistance),
        ],
      }));
    }
  };

  const handleStock = (e) => {
    if (e.target.checked) {
      setFilter({ ...filter, qty: { $ne: 0 } });
    } else setFilter({ ...filter, qty: { $exists: true } });
  };

  const resetForm = (e) => {
    setFilter({
      composition: [],
      categories: [],
      price: [properties.price[0], properties.price[1]],
      qty: { $exists: true },
    });
  };

  return (
    (filter.price[0] !== filter.price[1] ||
      properties.category.length > 1 ||
      properties.composition.length > 1) && (
      <form
        className="bg-neutral px-4 rounded-md py-5 z-20 md:w-fit w-full border-2 border-gray-300 md:border-none mx-auto max-w-sm sm:max-w-none"
        method="POST"
        onSubmit={submitFilter}
      >
        <div
          className="flex justify-between md:hidden"
          onClick={(e) => {
            e.preventDefault();
            setOpenFilter(!openFilter);
          }}
        >
          <legend className="text-xl">Фильтр</legend>
          <button className="w-6 text-gray-400">
            {!openFilter ? <ChevronDownIcon /> : <ChevronUpIcon />}
          </button>
        </div>
        <div
          className={
            (openFilter ? "flex" : "hidden") +
            " md:flex flex-col sm:flex-row md:flex-col items-center justify-center gap-3 sm:items-stretch flex-wrap mt-4 md:mt-0"
          }
        >
          {/* <span
            className=" text-right text-gray-500 hover:cursor-pointer hover:underline"
            onClick={resetForm}
          >
            Сбросить
          </span> */}
          {/* {properties.composition.length > 1 && (
            <fieldset className="border border-solid border-accent px-4 py-2 rounded-md">
              <legend className="uppercase text-lg font-semibold">
                Состав
              </legend>
              <div className="flex flex-col">
                {properties.composition.map((value, index) => (
                  <label
                    for={"comp" + index}
                    className="cursor-pointer whitespace-nowrap flex items-center"
                  >
                    <Checkbox
                      id={"comp" + index}
                      type="checkbox"
                      value={value}
                      className="mr-2 cursor-pointer w-4 h-4 accent-pink rounded border-gray-100"
                      onChange={handleComposition}
                    />
                    {value}
                  </label>
                ))}
              </div>
            </fieldset>
          )} */}
          {properties.category.length > 1 && (
            <fieldset className="border border-solid border-accent px-4 py-2 rounded-md ">
              <legend className="uppercase text-lg font-semibold">
                Категории
              </legend>
              <div className="flex flex-col ">
                {properties.category.map((value, index) => (
                  <label
                    for={"cat" + index}
                    className="cursor-pointer whitespace-nowrap flex items-center"
                  >
                    <Checkbox
                      id={"cat" + index}
                      type="checkbox"
                      value={value}
                      className="mr-2 cursor-pointer w-4 h-4 accent-pink rounded border-gray-100"
                      onChange={handleCategory}
                    />
                    {value}
                  </label>
                ))}
              </div>
            </fieldset>
          )}
          {filter.price[0] !== filter.price[1] && (
            <fieldset className="border border-solid border-accent px-4 py-2 rounded-md">
              <legend className="uppercase text-lg font-semibold">Цена</legend>
              <div className="flex flex-row gap-2 justify-between">
                <TextField
                  id="min-number"
                  label="От"
                  size="small"
                  className="w-[4.25rem]"
                  value={filter.price[0]}
                  onChange={handlePrice}
                />
                <TextField
                  id="max-number"
                  label="До"
                  size="small"
                  className="w-[4.25rem]"
                  value={filter.price[1]}
                  onChange={handlePrice}
                />
              </div>
              <Slider
                getAriaLabel={() => "Ценовой диапазон"}
                valueLabelDisplay="auto"
                disableSwap
                onChange={handlePrice}
                value={filter.price}
                min={properties.price[0]}
                max={properties.price[1]}
                className=" col-span-2"
              />
            </fieldset>
          )}
          <label className="mx-auto hover:cursor-pointer">
            В наличии{" "}
            <Checkbox
              id="inStock"
              type="checkbox"
              value={filter.qty}
              className="mr-2 cursor-pointer w-4 h-4 accent-pink rounded border-gray-100"
              onChange={handleStock}
            />
          </label>
          <button
            type="submit"
            className="bg-accent rounded-full py-2 px-4 text-white mt-2 place-self-center"
          >
            Применить
          </button>
        </div>
      </form>
    )
  );
}
