import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Store from "./Components/Store";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#49ae49",
    },
    error: {
      main: "#e50a80",
    },
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          "&.MuiFilledInput-root": {
            overflow: "hidden",
            borderRadius: 4,
            backgroundColor: "#fafcfa",
            "&.Mui-focused": {
              backgroundColor: "#ffffff",
              borderColor: "#49ae49",
            },
            "&.Mui-error": {},
          },
        },
      },
    },
  },
});

ReactDOM.render(
  <BrowserRouter>
    <Store>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Store>
  </BrowserRouter>,
  document.getElementById("root")
);

// window.addEventListener("resize", () => {
//   let docWidth =
//     document.documentElement.clientWidth || document.body.clientWidth;
//   document.body.style.maxWidth = docWidth;
//   console.log(docWidth);
// });
