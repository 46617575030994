import React, { useContext, useEffect, useState } from "react";
import {
  ShoppingBagIcon,
  XIcon,
  MinusIcon,
  PlusIcon,
  TruckIcon,
} from "@heroicons/react/outline";
import { StoreContext } from "./Store";
import { Dialog, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

const Cart = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [state, dispatch] = useContext(StoreContext);

  return (
    <div className="ml-4 flow-root lg:ml-6">
      <span className="">
        {isOpen ? (
          <BubbleButton
            size="10"
            color="accent"
            content={<XIcon className="h-6 w-6" />}
            onClick={(e) => {
              setIsOpen(false);
              document.body.scroll("yes");
            }}
          />
        ) : (
          <BubbleButton
            size="10"
            color="accent"
            content={
              <>
                <ShoppingBagIcon className="h-6 w-6" aria-hidden="true" />
                {state.cart.length ? (
                  <span className="absolute rounded-full text-sm bg-pink w-5 h-5 text-white flex justify-center items-center -bottom-2 -right-2">
                    {state.cart.length}
                  </span>
                ) : null}
              </>
            }
            onClick={(e) => {
              setIsOpen(true);
              document.body.scroll("no");
            }}
          />
        )}
      </span>
      <Transition
        show={isOpen}
        enter="transition duration-200 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100 "
        leave="transition duration-200 ease-out"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="z-40 bg-white h-screen w-screen fixed top-0 left-0"
        >
          <Dialog.Overlay />
          <div className="relative w-full h-full overflow-scroll bodywrapper pb-10">
            <h3 className="text-2xl font-semibold ">Корзина</h3>
            <CartList />
            {state.cart.length ? (
              <a
                href="/checkout"
                className="bg-accent rounded-md py-2 px-4 text-white mt-4 block w-min ml-auto hover:opacity-75 mr-5"
              >
                Оформить
              </a>
            ) : (
              <></>
            )}
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export function CartList({ delivery }) {
  const [state, dispatch] = useContext(StoreContext);

  return state.cart.length ? (
    <>
      <ul className="flex w-full items-stretch flex-col">
        {state.cart.map((item) => (
          <ProductTile product={item} changeCart={dispatch} />
        ))}
        {delivery ? (
          <li className="border-b border-gray-400 px-5 py-5 flex flex-row justify-between capitalize text-xl">
            <span className="flex flex-row items-center gap-2">
              <TruckIcon className="h-5" />
              Доставка:
            </span>
            <span>
              {delivery} {isNaN(delivery) ? "" : "₽"}
            </span>
          </li>
        ) : null}
      </ul>
      <div className="capitalize text-xl ml-auto mr-5 w-fit whitespace-nowrap my-5">
        <span className="mr-5 font-semibold">Итого:</span>
        {delivery
          ? state.totalSum + (isNaN(delivery) ? 0 : delivery)
          : state.totalSum}{" "}
        ₽
      </div>
    </>
  ) : (
    <p className="italic text-lg">Пусто</p>
  );
}

function ProductTile({ product, changeCart }) {
  return (
    <li className="border-b border-gray-400 px-5">
      <div className="flex w-full flex-row flex-nowrap justify-between gap-6">
        <Link
          className="relative py-7"
          to={`/product/${product._id}`}
          target=""
        >
          <h4 className="text-lg w-full absolute top-0 whitespace-nowrap">
            {product.title}
          </h4>
          <img
            src={product.image}
            alt=""
            className="aspect-1 object-cover w-full max-h-40 p-1 min-w-[2.5rem]"
          ></img>
        </Link>
        <div className="flex flex-row-reverse gap-4 md:flex-col md:gap-2 items-center my-auto w-full">
          <BubbleButton
            size="7"
            color="accent"
            content={<PlusIcon className="w-5 h-5"></PlusIcon>}
            onClick={(e) => {
              e.preventDefault();
              changeCart({ type: "INC_PRODUCT", payload: product._id });
            }}
          />
          <span className="">{product.qty}</span>
          <BubbleButton
            size="7"
            color="accent"
            content={<MinusIcon className="w-5 h-5"></MinusIcon>}
            onClick={(e) => {
              e.preventDefault();
              changeCart({ type: "DEC_PRODUCT", payload: product._id });
            }}
          />
        </div>

        {product.discount ? (
          <span className="capitalize text-xl max-w-[15%] text-right my-auto whitespace-nowrap relative text-pink">
            <span className="text-sm line-through text-black absolute -left-full bottom-1">
              {product.price * product.qty} ₽
            </span>
            {product.price * product.qty * (1 - product.discount / 100)} ₽
          </span>
        ) : (
          <span className="capitalizexl max-w-[15%] text-right my-auto whitespace-nowrap text-xl">
            {product.price * product.qty} ₽
          </span>
        )}

        <BubbleButton
          size="7"
          color="pink"
          content={<XIcon className="w-5 h-5"></XIcon>}
          onClick={(e) => {
            e.preventDefault();
            changeCart({ type: "REMOVE_PRODUCT", payload: product._id });
          }}
        />
      </div>
    </li>
  );
}

export default Cart;

const BubbleButton = ({ size, color, content, onClick }) => {
  const classes = `w-${size} h-${size} bg-${color}`;
  return (
    <button
      type="button"
      className="text-gray-400 group flex items-center justify-center relative hover:text-white z-20"
      onClick={onClick}
    >
      <div
        className={
          "absolute opacity-0 scale-0 group-hover:scale-100 group-hover:opacity-100 rounded-full -z-10 " +
          classes
        }
      ></div>
      {content}
    </button>
  );
};
