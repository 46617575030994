import React from "react";
import photo from "../../img/evie-s-bSVGnUCk4tk-unsplash.jpg";
import { CatalogBtn } from "./Components";
import {
  InformationCircleIcon,
  LocationMarkerIcon,
  PhoneIcon,
  SparklesIcon,
} from "@heroicons/react/outline";
import Logo from "../../Components/Logo";
import { Link } from "react-router-dom";

const btns = [
  {
    link: "/services",
    content: (
      <>
        <SparklesIcon className="w-6 text-pink " />
        Услуги
      </>
    ),
  },
  {
    link: "/contacts",
    content: (
      <>
        <LocationMarkerIcon className="w-6 text-pink" />
        Адреса
      </>
    ),
  },
];

const MainSearch = () => {
  return (
    <div className="relative w-full max-w-6xl p-6 min-h-[10rem] sm:min-h-[18rem] sm:rounded-xl overflow-hidden flex flex-row flex-wrap gap-6 justify-center items-center z-40 flex-shrink-0">
      <span className="hidden sm:flex items-center gap-2">
        <Logo className="flex-shrink-0" />
        <Link
          to="/"
          className="self-center text-4xl whitespace-nowrap text-pink font-['Taurus'] translate-y-[7%] "
        >
          La Florida
        </Link>
      </span>
      <div className="flex flex-wrap justify-center gap-4 sm:flex-col md:flex-row max-w-xs md:max-w-none">
        {/* <div className="shrink-0 grow md:hidden">
          <Link
            className="bg-[#f9e9f3] p-4 pr-5 rounded-xl gap-2 items-center min-w-fit flex
            sm:hover:scale-105 hover:shadow-xl shadow-lg group text-pink hover:text-accent font-[Taurus] text-xl cursor-pointer whitespace-nowrap"
            to="/deliveryinfo"
          >
            <InformationCircleIcon className="w-6 h-6  animate-pulse" />
            <span className=" -mb-1 animate-pulse">8 марта</span>
          </Link>
        </div> */}
        <CatalogBtn />
        {btns.map((btn) => (
          <div className="shrink-0 grow">
            <Link
              className="bg-white p-4 pr-5 rounded-xl gap-2 items-center min-w-fit flex
            sm:hover:scale-105 hover:shadow-xl shadow-lg group"
              to={btn.link}
            >
              {btn.content}
            </Link>
          </div>
        ))}
        <div className="shrink-0 grow max-w-fit whitespace-nowrap">
          <a
            className="bg-pink p-4 pr-5 rounded-xl gap-2 items-center flex
          sm:hover:scale-105 hover:shadow-xl shadow-lg group text-white"
            href="tel:+73422286000"
          >
            <PhoneIcon className="w-5 h-5" />
            <span className="hidden sm:block font-semibold">
              +7 (342) 228 60 00
            </span>
            <span className="block sm:hidden">Позвонить</span>
          </a>
        </div>
      </div>
      <img
        src={photo}
        alt=""
        className="w-full h-full object-bottom object-cover absolute -z-10 "
      />
    </div>
  );
};

export default MainSearch;
