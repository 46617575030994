import { ExclamationCircleIcon } from "@heroicons/react/outline";
import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";

function UndefinedPage() {
  return (
    <div className="mainWrapper">
      <div className="fixed w-full z-50">
        <Navbar />
      </div>
      <div className="bodywrapper pt-20">
        <h3 className="text-2xl font-semibold  mb-4">Страница не найдена</h3>
        <div className="mt-10 flex justify-center items-center">
          <div className="text-lg flex flex-col items-center rounded-lg bg-neutral px-4 py-6 shadow-md">
            <ExclamationCircleIcon className="w-10 m-2 text-gray-500" />
            Упс... Этой страницы не существует.
            <Link
              to="/"
              className="text-pink hover:text-accent italic underline hover:cursor-pointer"
            >
              Вернуться на главную
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default UndefinedPage;
